/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  height: 470px;
  transition: all 1000ms cubic-bezier(0.075, 0.820, 0.165, 1.000);

  &:hover {
    transition: all 1000ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
  }

  &:hover {
    .link-card:before {
      content: "";
      height: 40px;
      width: 40px;
      opacity: 1;
      transition: all ease 0.3s;
    }
  }

  @include media-breakpoint-down(xl) {
    height: 520px;
  }
  @include media-breakpoint-down(sm) {
    height: initial;
  }

  .custom-card-link {
    display: block;
  }

  /*
  * Card img
  */
  .card-img-container {
    height: 275px;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: transform 2s $easeOutExpo;
      will-change: transform;
    }

    .label {
      position: absolute;
      top: 15px;
      right: 15px;
      height: 53px;
      width: 53px;
      display: block;
      z-index: 1;
    }
  }

}

.card-content {
  &:hover {
    .card-img-container {
      .card-img {
        transform: scale(1.05);
      }
    }
  }

  h2 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 22px;
    }
  }


  .date-card {
    color: #253365;
    font-family: "Public Sans";
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    @include media-breakpoint-down(lg) {
      font-size: 13px;
      display: contents;
    }
  }

  .tag-card {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 13px;
      display: contents;
    }
  }

  .author-card {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
  }

  .link-card {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    position: absolute;
    bottom: 15px;
    left: 30px;
    @include media-breakpoint-down(sm) {
      margin: 20px 0 0 0 !important;
      padding: 0 !important;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
    }

    &:before {
      content: "";
      height: 0;
      width: 0;
      border: 1px solid #253365;
      border-radius: 50%;
      position: absolute;
      top: 0px;
      right: -90px;
      left: 0;
      bottom: 0;
      float: right;
      transition: all ease 0.3s;
      margin: auto;
      opacity: 0;
      @include media-breakpoint-down(sm) {
        right: -9px;
        left: initial;
      }
    }

    &:hover {
      &:before {
        content: "";
        height: 40px;
        width: 40px;
        opacity: 1;
        transition: all ease 0.3s;
      }
    }

    &:after {
      content: "";
      background-image: url('../img/icon/arr.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      width: 24px;
      height: 18px;
      display: block;
      cursor: pointer;
      margin: 3px 0px 0 17px;
      z-index: 1;
      float: right;
      transition: all ease 0.3s;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
    }
  }
}

.term-card {
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 2px 15px;
  margin-right: 5px;
  @include media-breakpoint-down(xl) {
    font-size: 10px;
    padding: 2px 8px;
    display: inline-block;
  }

  &:hover {
    color: white;
  }

  &.term-social {
    background-color: $orange;
  }

  &.term-energie,
  &.term-environnement {
    background-color: $green;
  }

  &.term-gouvernance {
    background-color: $blue1;
  }
}

.firstcard {
  .custom-card {
    height: 421px;
    @include media-breakpoint-down(xl) {
      height: 520px;
    }
    @include media-breakpoint-down(md) {
      height: initial;
    }

    &:hover {
      .link-card:before {
        content: "";
        height: 40px;
        width: 40px;
        opacity: 1;
        transition: all ease 0.3s;
      }
    }

    .custom-card-link {
      display: block;
    }

    .card-img-container {
      height: 421px;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(xl) {
        height: 520px;
      }
      @include media-breakpoint-down(md) {
        height: 275px;
      }
    }

    h2 {
      color: #253365;
      font-family: "Public Sans";
      font-size: 30px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 34px;
      text-align: left;
      @include media-breakpoint-down(sm) {
        font-size: 19px;
        line-height: 26px;
      }
    }

    .content-card {
      color: #253365;
      font-family: "Public Sans";
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 20px;
      text-align: left;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .link-card {
      position: relative;
      left: 0px;
    }
  }

  .flex-card {
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
  }

  h1 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 40px;
  }

  h2 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 40px;
  }

  h3 {
    @extend .title-lg;
  }

  h4 {
    @extend .title-md;
  }

  h5 {
    @extend .title-sm;
  }

  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    margin: 20px;
    padding: 0;

    li {
      list-style: disc;
    }
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;
    color: #253365;
    font-family: "Public Sans";
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    margin: 40px 0px 40px 70px;
    @include media-breakpoint-down(md) {
      margin: 25px;
    }

    &:before {
      content: "";
      position: absolute;
      border-left: 10px solid #253365;
      width: 100%;
      height: 100%;
      margin-left: -25px;
    }
  }

  .desc {
    color: #253365;
    font-family: "Public Sans";
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;

    p {
      font-size: 16px;
      line-height: 23px;
    }
  }
}

.link-arrow {
  color: #253365;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;

  &:after {
    content: "";
    background-image: url('../img/icon/arr.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 4px 0px 0 8px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s;
  }

  &:hover {
    &:after {
      content: "";
      margin: 4px 0px 0 14px;
      transition: all ease 0.3s;
    }
  }
}

.card-image {
  .legend {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    float: right;
    @include media-breakpoint-down(md) {
      float: left;
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 89px;
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.form-nl {
  .gform_wrapper {
    margin: 0;

    .gform_anchor {
      display: none;
    }

    form {
      position: relative;
      max-width: 470px;
      width: 100%;
      margin: 0 auto;

      .gform_body {
        margin: 0;

        #field_2_1,
        #field_3_1 {
          margin: 0;
          @include media-breakpoint-down(sm) {
            padding-right: 0;
          }

          label {
            color: white;
            font-family: "Public Sans";
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 20px;
            text-align: left;
            margin: 0;
            padding-bottom: 10px;
            display: none;
          }

          input {
            border: 1px solid white;
            width: 100%;
            padding: 10px 18px;
            margin: 0;
            color: white;
            min-height: 2rem;
            @include media-breakpoint-down(sm) {
              padding: 10px 8px !important
            }
            @include media-breakpoint-down(xs) {
              padding: 6px 8px !important
            }

            &:hover,
            &:focus,
            &:active {
              background: transparent !important
            }

            &::-webkit-input-placeholder {
              color: white;
            }

            &::-moz-placeholder {
              color: white;
            }

            &:-ms-input-placeholder {
              color: white;
            }

            &:-moz-placeholder {
              color: white;
            }

          }

          span {
            display: none;
          }

          &.gfield_error {
            #input_2_1,
            #input_3_1 {
              border: 1px solid #e4b1b1 !important;
            }
          }
        }

        #field_2_2,
        #field_3_2 {
          padding: 0;
          margin: 0;

          .gfield_label {
            display: none;
          }

          input {
            padding: 0;
            margin: 0;
          }

          #input_2_2_1,
          #input_3_2_1 {
            -webkit-appearance: radio;
            float: left;
            width: 22px;
            margin-top: 9px;
            color: white;
            font-family: "Public Sans";
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;
            line-height: 20px;
            text-align: left;

          }

          label.gfield_consent_label {
            color: white;
            font-family: "Public Sans";
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 18px;
            text-align: left;
            margin-top: 7px;

            a {
              color: white;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &.gfield_error {
            .gfield_consent_label {
              color: #e4b1b1;
            }
          }
        }

        .ginput_container {
          margin-top: 0px;
          display: flex;
        }

        .gfield.gfield_error {
          background: none;
          margin-bottom: 0 !important;
          border: none;
          padding: 0;

          .validation_message {
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            color: #e4b1b1;
          }
        }
      }

      .gform_footer {
        padding: 0px;
        margin: 0;
        clear: both;
        width: inherit;
        position: absolute;
        top: 0;
        right: 30px;
        border: none;

        .btn_arrow {
          content: "";
          background-image: url('../img/icon/arr-w.svg');
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: center;
          background-color: $blue3;
          width: 40px;
          height: 44px;
          display: block;
          cursor: pointer;
          margin: 0;
          position: absolute;
          top: 1px;
          left: initial;
          right: -13px;
          z-index: 1;
          @include media-breakpoint-down(sm) {
            right: -29px;
          }
        }
      }

      .validation_error {
        display: none;
      }
    }
  }

  .gform_confirmation_message {
    color: green;
  }

  #gform_ajax_spinner_2,
  #gform_ajax_spinner_3 {
    display: block;
    position: absolute;
    right: -15px;
    top: 50px;
    height: 50px;
    width: 50px;

    img {
      height: 50px;
      width: 50px;
    }
  }
}

.banner-intro {
  height: 322px;
  background-position: right;
  background-repeat: no-repeat;
  background-color: $blue3;
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  display: flex;
  vertical-align: middle;
  align-items: center;

  a {
    color: white;
  }
}

.prefooter-block.block-newsletter {
  color: white;

  h2 {
    color: white;
    text-align: center;
  }
}

#gform_confirmation_message_2,
#gform_confirmation_message_3 {
  color: white;
}

.prefooter-block.prefooter {
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center;
}

/*
|
| FAQ
|---------------
*/
.block-accordion {
  position: relative;

  h2, h3 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left;
    width: 100%;
  }

  input[type="text"] {
    position: absolute;
    top: 0;
    right: 15px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $blue3;
    height: 50px;
    padding-left: 50px;
    background-image: url('/wp-content/themes/yagami-adveris/web/src/img/icon/search.svg');
    background-repeat: no-repeat;
    background-position: 15px center;
    @include media-breakpoint-down(sm) {
      position: relative;
      width: 100%;
      left: inherit;
      right: initial;
    }
  }


  .accordion-item.hide,
  .groupe.hide {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none;
    border: none;
  }

  h2 {
    padding: 0 0 1em 0;
    border-bottom: 1px solid $blue3;
  }

  h3 {
    padding: 1em 1.5em 1em 0;
    border-bottom: 1px solid $blue3;
  }

  .accordion {
    .accordion-item {
      border-bottom: 1px solid $blue3;
      opacity: 1;
      visibility: visible;
      transition: visibility .2s ease-out, opacity .2s ease-out;

      button {
        position: relative;
        display: block;
        text-align: left;
        width: 100%;
        padding: 1em 0;
        font-size: 1.15rem;
        font-weight: 300;
        border: none;
        background: none;
        outline: none;

        &:hover, &:focus {
          cursor: pointer;
          color: $blue3;
          font-weight: 700;

          &::after {
            cursor: pointer;
            color: $blue3;
            border: 1px solid $blue3;
          }
        }

        .accordion-title {
          padding: 1em 1.5em 1em 0;
          color: #253365;
          font-family: "Public Sans";
          font-size: 20px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 26px;
          text-align: left;
          width: calc(100% - 60px);
        }

        .icon {
          display: inline-block;
          position: absolute;
          top: 24px;
          right: 0;
          width: 56px;
          height: 56px;
          border: 1px solid $blue3;
          border-radius: 50%;

          &::before {
            content: "";
            background-image: url('../img/icon/arr.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
            width: 24px;
            height: 18px;
            display: block;
            cursor: pointer;
            z-index: 1;
            position: absolute;
            transition: all ease 0.3s;
            top: 18px;
            left: 14px;
          }
        }
      }

      button[aria-expanded='true'], &:hover {
        color: $blue3;

        .icon {
          &::after {
            width: 0;
          }
        }

        + .accordion-content {
          opacity: 1;
          max-height: inherit;
          transition: all 200ms linear;
          will-change: opacity, max-height;
          @include media-breakpoint-down(md) {
            max-height: inherit;
          }
        }

        .accordion-title {
          font-weight: 600;
          transition: all ease 0.3s;
        }

        .icon {
          background-color: $blue3;

          &::before {
            content: "";
            background-image: url('../img/icon/arr-w.svg');
          }
        }
      }

      button[aria-expanded='true'] {
        .icon {
          &::before {
            content: "";
            transform: rotate(90deg);
          }
        }
      }

      .accordion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;

        p {
          font-size: 1rem;
          font-weight: 300;
          margin: 10px 0 30px;
        }
      }
    }
    ul{
      padding-left: 7px;
      li{
        position: relative;
        padding-left: 10px;
          &:before{
            content: "";
            height: 3px;
            width: 3px;
            display: block;
            position: absolute;
            left: 0px;
            top: 10px;
            background-color: #000;
            border-radius:50%;
          }
      }
    }
  }

}

/*
|
| A propos
|---------------
*/
#page-apropos {
  padding-top: 89px;

  .banner-intro {
    background-position: left;
  }

  #swiper-container {
    overflow: hidden;

    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        margin: auto;

        .block-img {
          display: flex;
          text-align: center;
          vertical-align: middle;
          align-items: center;
          margin: auto;
          height: 60px;

          img {
            max-width: 60%;
            max-height: 60px;
            display: block;
            margin: auto;
            filter: grayscale(100%);
            transition: all ease 0.4s;
            @include media-breakpoint-down(md) {
              max-height: 60px;
            }
          }
        }

        a {
          display: block;
          color: #253365;
          font-family: "Public Sans";
          font-size: 14px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 20px;
          text-decoration: underline;
          text-align: center;
        }

        &:hover {
          img {
            filter: initial;
            transition: all ease 0.4s;
          }
        }
      }
    }
  }

  .swiper-button-prev {
    outline: none;
    left: 20px;
    right: inherit;
    z-index: 9 !important;
  }

  .swiper-button-next {
    outline: none;
    left: inherit;
    right: 20px;
    z-index: 9 !important;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    content: "";
    background-image: url('../img/icon/arr.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f5f5f5;
    background-size: 20px;
    border: 1px solid $blue3;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    display: block;
    cursor: pointer;
    margin: 0px;
    position: absolute;
    bottom: 0px;
    right: -17px;
    z-index: 1;
    transform: rotate(180deg);
  }

  .swiper-button-prev {
    &:hover {
      &:after {
        content: "";
        background-image: url('../img/icon/arr-w.svg');
        background-color: $blue3;
      }
    }
  }

  .swiper-button-next {
    &:after {
      content: "";
      left: -17px;
      right: initial;
      transform: rotate(0deg);
    }

    &:hover {
      &:after {
        content: "";
        background-image: url('../img/icon/arr-w.svg');
        background-color: $blue3;
      }
    }
  }

  #restons-en-contact {
    h2 {
      color: #ffffff;
    }

    img {
      padding: 4px 20px;
      float: left;
    }

    ul {
      margin: 15px 0;

      li {
        color: #ffffff;
        padding-bottom: 50px;

        &:last-child {
          padding-bottom: 0px;
        }

        a,
        p {
          color: #fff;
        }
      }
    }

    &:before {
      content: "";
      background-image: url('../img/global/filter_contact.png');
      height: 239px;
      width: 238px;
      position: absolute;
      left: -50px;
      bottom: -50px;
      opacity: 0.3;
    }
  }

}

.block-left {
  height: 650px;
  margin-top: -60px !important;
  @include media-breakpoint-down(md) {
    height: initial !important;
    margin-top: 0px !important;
  }

  .bloc-img-left {
    position: absolute;
    @include media-breakpoint-down(md) {
      position: relative;
    }

    .img-left {
      height: 650px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      max-width: 100%;
      height: inherit;
      margin: 20px auto;
      @include media-breakpoint-down(md) {
        margin: 0 auto 20px auto;
      }
    }
  }

  .block-cont {
    height: 650px;
    @include media-breakpoint-down(md) {
      height: initial;
    }
  }
}

.block-left,
.block-right {
  height: 650px;
  margin-top: -60px !important;
  @include media-breakpoint-down(md) {
    height: initial !important;
    margin-top: 0px !important;
  }

  .bloc-img-left,
  .bloc-img-right {
    position: absolute;
    @include media-breakpoint-down(md) {
      position: relative;
    }

    .img-left,
    .img-right {
      height: 650px;
      //background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      max-width: 100%;
      height: inherit;
      margin: 20px auto;
      @include media-breakpoint-down(md) {
        margin: 0 auto 20px auto;
      }
    }
  }

  .block-cont {
    height: 650px;
    @include media-breakpoint-down(md) {
      height: initial;
    }
  }
}

.block-right {
  margin-top: 0px !important;
}

/*
|
| Page CMS
|---------------
*/
.page-template-cms {
  background-color: #fff;

  #page-cms {
    h1 {
      color: $blue3;
    }
  }

  h2 {
    margin-top: 30px;
  }

  p {
    margin: 15px 0;
  }
}

/*
|
| Contenu flexible
|---------------
*/

.flexible {
  padding-bottom: 30px;

  .section_image_texte {
    &.block-left {
      height: 650px;
      margin-top: 50px !important;
    }
  }

  .section_texte_image {
    &.block-right {
      height: 650px;
      margin-top: 50px !important;
    }
  }

  .section_marque {
    .image {
      width: 119px;
      height: 119px;
      min-width: 119px;
      background-color: #f5f5f5;
      border-radius: 50%;
      vertical-align: middle;
      align-items: center;
      display: flex;

      img {
        width: 100%;
        padding: 20px;
      }
    }

    .detail {
      padding: 0 0 0 20px;
    }
  }

  .section_faq {
    .accordion-item {
      padding: 20px 0 !important;
    }

    .accordion-content {
      p {
        margin: 20px 0 0px !important;
      }
    }

    button {
      padding: 0 0 !important;

      .image {
        width: 61px;
        max-width: 61px;
        height: 61px;
        border-radius: 50%;
        background-size: cover;
        float: left;
        margin-right: 20px;

        &.img-def {
          background-image: url('../img/global/def-img.png');
        }
      }

      .icon {
        top: initial !important;
      }

      .accordion-title {
        padding: 1em 60px 1em 0 !important;
      }
    }
  }

  .section_glossaire {
    h2 {
      margin-bottom: 0px;
    }

    .elem {
      border-bottom: 1px solid $blue3;

      h3 {
        color: #253365;
        font-family: "Public Sans";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left;
      }

      p {
        margin: 0;
      }
    }
  }

  .section_bon_a_savoir {
    color: #ffffff;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left;

    .bg {
      background-color: $blue3;
      padding: 60px 90px;
      @include media-breakpoint-down(md) {
        padding: 40px 30px;
      }

      h3 {
        font-size: 20px;
      }

      img {
        height: 27px;
        margin-right: 10px;
      }

      .title {
        color: #ffffff;
        font-family: "Public Sans";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left;
      }

      p {
        margin-bottom: 0;
      }
    }

  }

  .section_encart {
    img {
      margin: auto;
    }
  }
}

.back-btn-container {
  width: 100%;

  &:after {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    background-color: $blue3;
  }

  a {
    color: $blue3;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    padding-bottom: 30px;

    &:before {
      content: "";
      background-image: url('../img/icon/arr.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(-180deg);
      background-size: 20px;
      width: 24px;
      height: 18px;
      display: block;
      cursor: pointer;
      margin: 2px 10px 0 0;
      z-index: 1;
      float: left;
      transition: all ease 0.3s;
    }
  }
}

/*
|
| Projects
|---------------
*/

#page-projects {
  background-color: #fff;

  .banner-intro {
    a {
      text-decoration: underline;
    }
  }

  .block-mea {
    article {
      background-color: $light-grey;
      height: 210px;
      padding: 40px;
      @include media-breakpoint-down(md) {
        height: initial;
        padding: 30px 20px;
      }

      .elem {
        &:after {
          content: "";
          width: 1px;
          background-color: #c0c0c0;
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          height: 130px;
          @include media-breakpoint-down(md) {
            content: none;
          }
        }

        &:last-child {
          &:after {
            content: none;
          }
        }

        h2 {
          color: #253365;
          font-family: "Public Sans";
          font-size: 24px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: 26px;
          text-align: left;
          @include media-breakpoint-down(md) {
            font-size: 20px;
          }
        }

        &.elem-perf {
          text-align: right;
          @include media-breakpoint-down(md) {
            text-align: initial;
          }
        }
      }
    }
  }

  .list-solutions {
    .hide {
      animation: hide .5s ease 0s 1 normal forwards;
      transform-origin: center;
      display: none;
    }

    .show {
      animation: show .5s ease 0s 1 normal forwards;
      transform-origin: center;
      display: block;
    }

    @keyframes hide {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
        width: 0;
        height: 0;
        margin: 0;
      }
    }
    @keyframes show {
      0% {
        transform: scale(0);
        width: 0;
        height: 0;
        margin: 0;
      }
      100% {
        transform: scale(1);
      }
    }


    .why-title {
      cursor: pointer;

      span {
        width: calc(100% - 40px);
      }

      &:before {
        content: "";
        display: block;
        height: 1px;
        width: calc(100% - 30px);
        background-color: #253365;
        position: absolute;
        bottom: -10px;
      }

      &:after {
        content: "";
        background-image: url('../img/icon/arr-blue.svg');
        height: 15px;
        width: 25px;
        display: block;
        position: absolute;
        top: 11px;
        right: 30px;
        transition: all ease 0.3s;
        transform: rotate(-180deg);
      }

      &.active {
        &:after {
          content: "";
          transition: all ease 0.3s;
          transform: rotate(0deg);
        }
      }
    }

    .why-txt {

    }

    .filters {
      margin: 20px 0;
      justify-content: center;
      text-align: center;

      .filter {
        color: #989fb9;
        font-family: "Public Sans";
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 24px;
        text-align: left;
        padding: 0 79px;
        transition: all ease 0.3s;
        @include media-breakpoint-down(xl) {
          padding: 0 52px;
        }
        @include media-breakpoint-down(lg) {
          padding: 0 27px;
        }
        @include media-breakpoint-down(md) {
          padding: 0;
          text-align: center;
        }

        &.active, &:hover {
          transition: all ease 0.3s;
          color: $blue3;
          opacity: 1;
        }

        &:after {
          content: "";
          width: 2px;
          opacity: 1;
          background-color: $blue3;
          display: block;
          position: absolute;
          right: 0px;
          top: 14px;
          height: 25px;
          @include media-breakpoint-down(md) {
            content: none;
          }
        }

        &:first-child {
          text-align: left;
          padding: 0 62px 0 0;
          @include media-breakpoint-down(xl) {
            padding: 0 50px 0 0;
          }
          @include media-breakpoint-down(md) {
            padding: 0;
            text-align: center;
          }
        }

        &:last-child {
          text-align: right;
          padding: 0 0 0 62px;
          @include media-breakpoint-down(xl) {
            padding: 0 0 0 50px;
          }
          @include media-breakpoint-down(md) {
            padding: 0;
            text-align: center;
          }
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }

      .filter:hover {
        cursor: pointer;
      }
    }
  }

  .list-palmares {
    h2 {
      color: #253365;
      font-family: "Public Sans";
      font-size: 32px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 37px;
      text-align: left;
    }

    .sstitle {
      text-align: left;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .perf {
      text-align: right;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}


.list-filter,
.block-solutions {
  .link-card {
    left: 20px;
    @include media-breakpoint-down(sm) {
      left: 0px;
    }
  }

  @include media-breakpoint-down(md) {
    .tabs {
      text-align: left !important;
    }
  }
}

#page-news-archive {

  .hide {
    animation: hide .5s ease 0s 1 normal forwards;
    transform-origin: center;
    display: none;
  }

  .show {
    animation: show .5s ease 0s 1 normal forwards;
    transform-origin: center;
    display: block;
  }

  @keyframes hide {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
      width: 0;
      height: 0;
      margin: 0;
    }
  }
  @keyframes show {
    0% {
      transform: scale(0);
      width: 0;
      height: 0;
      margin: 0;
    }
    100% {
      transform: scale(1);
    }
  }

  .link-card {
    left: 30px !important;
    @include media-breakpoint-down(sm) {
      left: 0px !important;
    }
  }

  .firstcard {
    .link-card {
      left: 0px !important;
    }
  }

}
.filters {
  position: relative;
  width: 100%;
  z-index: 1;

  .filter-mobil {
    width: 100%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  a {
    &.selecteur {
      position: relative;
      text-align: left;
      @include media-breakpoint-down(md) {
        border: none;
        border-bottom: 1px solid #253365;
        padding: 10px 20px;
      }

      &.active {
        &:after {
          content: "";
          transform: rotate(-180deg);
        }
      }

      &:after {
        content: "";
        background-image: url('../img/icon/arr-blue.svg');
        background-size: contain;
        background-position: 0 0px;
        background-repeat: no-repeat;
        height: 15px;
        width: 20px;
        display: block;
        position: absolute;
        top: 17px;
        right: 20px;
        transition: all ease 0.3s;
        transform: rotate(0deg);
      }
    }

    &.all {
      color: #253365;
      font-family: "Public Sans";
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      text-transform: uppercase;
      padding: 0;
      border-bottom: 1px solid $blue3;
      @include media-breakpoint-down(md) {
        border: 1px solid #253365;
        border-top: none;
        padding: 10px 20px;
      }
    }

    @include media-breakpoint-down(md) {
      border-radius: 0;
      padding: 10px 20px;
      font-size: 16px;
      border: 1px solid #253365;
      border-top: none;
      margin: 0 !important;
    }
  }
}

.line-blue {
  position: relative;

  &:after {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    display: block;
    position: absolute;
    background-color: #253365;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

/**
 * Overlay IE Browser
 */

 .browser-ie {

  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $white;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }


  img {

    margin-bottom: 50px;

  }

  .text-info {

    font-family: $font-family-default;
    font-size: $default-font-size;
    color: $black;

  }

 }