/*
|--------------------
|     PAGE HOME
|--------------------
*/

#page-home {
  .banner {
    @extend .bg-cover;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 600px;
    margin-top: 89px;
    background-color: $black;
    overflow: hidden;

    &:before {
      content: "";
      background-color: #000;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .item-content {
      width: 100%;
      padding: 0px 0;
      @include media-breakpoint-down(md) {
        padding: 30px 0 0 0;
      }

      .container {
        @include media-breakpoint-down(sm) {
          text-align: center;
        }

        .surtitle {
          color: #ffffff;
          font-family: "Public Sans";
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 24px;
          text-align: left;
          margin: 0px 0 10px 0;
          text-transform: uppercase;
          @include media-breakpoint-down(md) {
            margin: 35px 0 10px 0;
          }
          @include media-breakpoint-down(sm) {
            text-align: center;
          }
        }

        .big-title {
          color: #ffffff;
          font-family: "Public Sans";
          font-size: 48px;
          line-height: 50px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          @include media-breakpoint-down(lg) {
            font-size: 40px;
          }
          @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 37px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 26px;
            text-align: center;
          }

          .item-line {
            text-align: left;
            @include media-breakpoint-down(md) {
              text-align: center;
            }
          }
        }

        .resume {
          color: #ffffff;
          font-family: "Public Sans";
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 24px;
          text-align: left;
          @include media-breakpoint-down(sm) {
            text-align: center;
          }
        }

        .link {
          a {
            color: #ffffff;
            font-family: "Public Sans";
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 21px;
            text-align: left;
            position: relative;
            transition: all ease 0.3s;
            @include media-breakpoint-down(sm) {
              margin-left: -50px;
            }

            &:after {
              content: "";
              background-image: url('../img/icon/arr-w.svg');
              background-repeat: no-repeat;
              background-size: 20px;
              width: 21px;
              height: 13px;
              display: block;
              cursor: pointer;
              margin: 0px 0 0 10px;
              position: absolute;
              top: 5px;
              right: -40px;
              z-index: 1;
              transition: all ease 0.3s;
            }

            &:before {
              content: "";
              height: 0;
              width: 0;
              border: 1px solid #fff;
              border-radius: 50%;
              position: absolute;
              top: 0px;
              right: -150px;
              left: 0;
              bottom: 0;
              float: right;
              transition: all ease 0.3s;
              margin: auto;
              opacity: 0;
            }

            &:hover {
              &:after {
                content: "";
                right: -40px;
                transition: all ease 0.3s;
              }

              &:before {
                content: "";
                height: 40px;
                width: 40px;
                opacity: 1;
                transition: all ease 0.3s;
              }
            }
          }

          @include media-breakpoint-down(md) {
            margin-bottom: 50px;
          }
        }

      }

      .link-zone {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        @include media-breakpoint-down(md) {
          position: initial;
        }

        .link-banner {
          .item {
            height: 200px;
            width: 100%;
            padding: 40px 80px 40px 30px;
            position: relative;
            transition: all 1000ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
            @include media-breakpoint-down(md) {
              padding: 15px 94px 15px 30px;
              position: relative;
              height: 120px;
              display: flex;
              vertical-align: middle;
              align-items: center;
            }

            &:after {
              content: "";
              background-image: url('../img/icon/arr-w.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 20px;
              border: 1px solid #fff;
              border-radius: 50%;
              width: 54px;
              height: 54px;
              display: block;
              cursor: pointer;
              margin: 0px;
              position: absolute;
              bottom: 15px;
              right: 20px;
              z-index: 1;
              transition: all 1000ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
              @include media-breakpoint-down(md) {
                top: 33px;
                bottom: initial;
              }
            }

            &:nth-child(1) {
              background-color: #0587fc;
            }

            &:nth-child(2) {
              background-color: #105a9d;
            }

            &:nth-child(3) {
              background-color: #253365;
            }

            .firstword {
              display: block;
              @include media-breakpoint-down(md) {
                display: inline-block;
              }
            }

            &.inactive {
              opacity: 0.7 !important;
            }

            &:hover {
              &:after {
                content: "";
                right: 15px;
                background-color: #fff;
                background-image: url('../img/icon/arr.svg');
                transition: all 1000ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
              }
            }

            .title {
              color: #ffffff;
              font-family: "Public Sans";
              font-size: 24px;
              font-weight: 500;
              font-style: normal;
              letter-spacing: normal;
              line-height: 28px;
              text-align: left;
              @include media-breakpoint-down(lg) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .news {
    .all-posts {
      color: #253365;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      float: right;
      padding-top: 20px;
      transition: all ease 0.3s;
      @include media-breakpoint-down(md) {
        padding-top: 10px;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }

      &:before {
        content: "";
        height: 0;
        width: 0;
        border: 1px solid #253365;
        border-radius: 50%;
        position: absolute;
        top: 17px;
        right: -170px;
        left: 0;
        bottom: 0;
        float: right;
        transition: all ease 0.3s;
        margin: auto;
        opacity: 0;
      }

      &:hover {
        &:before {
          content: "";
          height: 40px;
          width: 40px;
          opacity: 1;
          transition: all ease 0.3s;
        }
      }

      &:after {
        content: "";
        background-image: url('../img/icon/arr.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        width: 24px;
        height: 18px;
        display: block;
        cursor: pointer;
        margin: 2px 0px 0 14px;
        z-index: 1;
        float: right;
        transition: all ease 0.3s;
      }
    }

    .mob-actu {
      display: none;
      @include media-breakpoint-down(xs) {
        display: block;
      }

      a {
        padding: 10px 20px;
        float: inherit;
        margin: 30px 0 0 0;
        border: 1px solid $blue3;
        display: block;
        font-weight: 500;
      }
    }
  }

  .news {
    .line {
      line-height: 42px !important;
    }
  }

  .block-finances {
    min-height: 600px;

    .flex {
      display: flex;
      align-items: center;
      height: 600px;
      padding: 0 60px;
      z-index: 2;
      @include media-breakpoint-down(md) {
        height: initial;
        padding: 0 15px;
      }

      .link {
        position: relative;
        display: inline-block;
        transition: all ease 0.3s;
        font-weight: 600;

        &:before {
          content: "";
          height: 0;
          width: 0;
          border: 1px solid #253365;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          right: -119px;
          left: 0;
          bottom: 0;
          float: right;
          transition: all ease 0.3s;
          margin: auto;
          opacity: 0;
        }

        &:hover {
          &:before {
            content: "";
            height: 40px;
            width: 40px;
            opacity: 1;
            transition: all ease 0.3s;
          }
        }

        &:after {
          content: "";
          background-image: url('../img/icon/arr.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          width: 24px;
          height: 18px;
          display: block;
          cursor: pointer;
          margin: 3px 0px 0 17px;
          z-index: 1;
          float: right;
          transition: all ease 0.3s;
        }
      }
    }

    .intro-img {
      position: absolute;
      top: 90px;
      right: 0;
      z-index: 1;
      @include media-breakpoint-down(md) {
        position: relative;
        top: initial;
        right: initial;
      }

      .img {
        background-size: cover;
        background-position: center;
        height: 740px;
        @include media-breakpoint-down(md) {
          height: 500px;
        }
        @include media-breakpoint-down(sm) {
          height: 340px;
        }
      }
    }
  }

  .block-homelink {
    color: white;

    .item {
      height: 260px;
      position: relative;
      overflow: hidden;

      &.block-esp {
        &:before {
          content: "";
          background-image: url(../img/global/nouveau.svg);
          height: 172px;
          width: 175px;
          display: block;
          top: -15px;
          right: -15px;
          position: absolute;
          opacity: 0;
          transition: all ease 0.3s;
        }

        &:hover {
          &:before {
            content: "";
            transition: all ease 0.3s;
            opacity: 1;
            top: 0px;
            right: 0px;
          }
        }
      }

      h3 {
        color: #ffffff;
        font-family: "Public Sans";
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28px;
        text-align: left;
      }

      .desc {
        color: #ffffff;
        font-family: "Public Sans";
        font-size: 15px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 20px;
        text-align: left;
      }

      .title {
        width: 104px;
        height: 24px;
        color: #ffffff;
        font-family: "Public Sans";
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: normal;
        line-height: 24px;
        text-align: left;
        float: right;
        position: absolute;
        right: 34px;
        bottom: 35px;
        margin-right: 60px;

        &:after {
          content: "";
          background-image: url('../img/icon/arr-w.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          border: 1px solid #fff;
          border-radius: 50%;
          width: 54px;
          height: 54px;
          display: block;
          cursor: pointer;
          margin: -14px -63px 0 0;
          bottom: 15px;
          right: 20px;
          z-index: 1;
          float: right;
        }
      }
    }
  }
}