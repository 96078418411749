/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $default-link-color;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  background-color: $light-grey;

  &.page-id-40 {

  }
}

#page-news-single,
#page-comprendre {
  background-color: $white;
}

h1 {
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 50px;
  line-height: initial;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  @include media-breakpoint-down(md) {
    font-size: 34px;
    line-height: initial;
  }
}

h2 {
  color: $blue3;
  font-family: "Public Sans";
  font-size: 40px;
  line-height: 44px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 11px;

  &.line {
    border-bottom: 1px solid $blue3;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 32px;
  }
}

button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

img {
  max-width: initial;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

.arr-b {
  float: right;

  &:after {
    content: "";
    background-image: url('../img/icon/arr.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 4px 0px 0 8px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s;
  }
}

.mobi-disp {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.desk-disp {
  display: block !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.desk-disp-flex {
  display: flex !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.nopad {
  padding-left: 0;
  padding-right: 0;
}

.z1 {
  z-index: 1;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.flex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.dflex {
  display: flex;
}

.txt-c {
  text-align: center !important;
}

.txt-r {
  text-align: right !important;
}

.rel {
  position: relative;
}