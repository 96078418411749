/*
|--------------------
|      NEWS
|--------------------
*/

#page-news-archive{

    .js-loaded-posts{

        & > .card-filter{

            margin-top: 15px !important;
            margin-bottom: 15px !important;

        }
    }


}

.load-more-container {

    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

