/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 50px 0 20px 0px;
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
  @include media-breakpoint-down(lg) {
    .item-logo {
      margin-bottom: 15px;
    }
  }

  a{
    &:hover{
      text-decoration: underline;
    }
  }
  .contact-form {
    .gform_wrapper {
      margin: 0;
      .gform_anchor {
        display: none;
      }
      form {
        position: relative;
        max-width: 470px;
        width: 100%;
        margin: 0 auto;
        .gform_body {
          margin: 0;
          #field_1_1 {
            margin: 0;
            @include media-breakpoint-down(sm) {
              padding: 0;
            }
            label {
              color: #253365;
              font-family: "Public Sans";
              font-size: 16px;
              font-weight: 600;
              font-style: normal;
              letter-spacing: normal;
              line-height: 20px;
              text-align: left;
              margin: 0;
              padding-bottom: 10px;
            }
            input {
              border: 1px solid $blue3;
              width: 100%;
              padding: 10px 18px;
              margin: 0;
              @include media-breakpoint-down(sm) {
                padding: 10px 8px!important
              }
              @include media-breakpoint-down(xs) {
                padding: 6px 8px!important
              }
              &::-webkit-input-placeholder {
                color: #a2a2a2;
              }
              &::-moz-placeholder {
                color: #a2a2a2;
              }
              &:-ms-input-placeholder {
                color: #a2a2a2;
              }
              &:-moz-placeholder {
                color: #a2a2a2;
              }
            }
            span {
              display: none;
            }
            &.gfield_error {
              #input_1_1 {
                border: 1px solid #790000 !important;
              }
            }
          }
          #field_1_2 {
            padding: 0;
            margin: 0;
            .gfield_label {
              display: none;
            }
            input {
              padding: 0;
              margin: 0;
            }
            #input_1_2_1 {
              -webkit-appearance: radio;
              float: left;
              width: 22px;
              margin-top: 9px;
              color: #253365;
              font-family: "Public Sans";
              font-size: 12px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: normal;
              line-height: 20px;
              text-align: left;

            }
            label.gfield_consent_label {
              color: #253365;
              font-family: "Public Sans";
              font-size: 12px;
              font-weight: 600;
              font-style: normal;
              letter-spacing: normal;
              line-height: 18px;
              text-align: left;
              margin-top: 7px;
              a {
                color: #253365;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &.gfield_error {
              .gfield_consent_label {
                color: red;
              }
            }
          }
          .ginput_container {
            margin-top: 0px;
            display: flex;
          }
          .gfield.gfield_error {
            background: none;
            margin-bottom: 0 !important;
            border: none;
            padding: 0;
            .validation_message {
              padding: 0;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
        .gform_footer {
          padding: 0px;
          margin: 0;
          clear: both;
          width: inherit;
          position: absolute;
          top: 0;
          right: 30px;
          border: none;
          .btn_arrow {
            content: "";
            background-image: url('../img/icon/arr.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: center;
            background-color: transparent;
            width: 40px;
            height: 44px;
            display: block;
            cursor: pointer;
            margin: 0 0px 0 0;
            position: absolute;
            top: 33px;
            left: initial;
            right: -13px;
            z-index: 1;
            @include media-breakpoint-down(sm) {
              right: -29px;
            }
          }
        }
        .validation_error {
          display: none;
        }
        #gform_ajax_spinner_1 {
          display: block;
          position: absolute;
          right: -10px;
          top: -15px;
          img{
          }
        }
      }
    }
    .gform_confirmation_message {
      color: green;
    }
  }

  .cms {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
    ul {
      li,
      li a {
        color: #253365;
        font-family: "Public Sans";
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left;
        @include media-breakpoint-down(lg) {
          text-align: center;
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid #c3c3c3;
    padding-top: 20px;
    padding-bottom: 2px;
  }
  .copyright, .copyright a {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: center;
  }

}