/*
|--------------------
|  Single Actualité
|--------------------
*/

.post-date {
  font-size: 12px;
  color: rgba($grey, 0.6);
  text-transform: uppercase;
  font-weight: 400;
}

.post-excerpt {
  font-size: 22px;
  color: rgba($very-dark-grey, 0.6);
  font-weight: 400;
}

/*
|--------------------
|  Single product
|--------------------
*/

.single-projects {
  background-color: #fff;
  color: $blue3;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  h1 {
    color: $blue3;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left;
  }
  h2 {
    color: $blue3;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left;
  }
  h3 {
    color: $blue3;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    text-transform: uppercase;
  }
  .card-image {
    .img-project {
      width: 233px;
      height: 233px;
      background-size: cover;
      border-radius: 50%;
      margin: auto;
    }
  }
  .ddl {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    position: relative;
    &:after {
      content: "";
      background-image: url('../img/icon/ddl.svg');
      background-repeat: no-repeat;
      height: 18px;
      width: 18px;
      display: block;
      cursor: pointer;
      transition: all ease 0.3s;
      position: absolute;
      right: -24px;
      top: 3px;
    }
  }
}
.block-info {
  position: relative;
  @include media-breakpoint-down(md) {
    height: initial;
    margin: 0;
    padding-top: 30px;
  }
  &:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: $blue3;
    position: absolute;
    top: 0;
  }
  @include media-breakpoint-down(md) {
    &:first-child {
      &:after {
        content: initial;
      }
    }
  }
  .info {
    display: block;
    padding-top: 24px;
    padding-bottom: 10px;
    @include media-breakpoint-down(md) {
      height: initial;
      padding: 0 0px 15px 0px;
    }
    span {
      display: initial;
      font-weight: bold;
    }
  }
}

.card-projects {
  .custom-card {
    height: 400px;
    border: 1px solid;
    padding: 15px 20px;
    position: relative;
    @include media-breakpoint-down(xl) {
      height: 450px;
    }
    @include media-breakpoint-down(lg) {
      height: 400px;
    }
    @include media-breakpoint-down(md) {
      height: initial;
    }
    .card-img-container {
      width: 110px;
      height: 110px;
      .card-img {
        width: 110px;
        height: 110px;
        border-radius: 200px;
      }
    }
    .card-content {
      .cms {
        color: #253365;
        font-family: "Public Sans";
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 22px;
        text-align: left;
      }
      .rate {
        width: 120px;
        float: left;
      }
      .perf {
        color: #253365;
        font-family: "Public Sans";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 17px;
        text-align: left;
        float: left;
      }
    }
    &:before {
      content: "";
      opacity: 0;
      border: 1px solid $blue3;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0px;
      position: absolute;
      transition: all ease 0.4s;
    }
    &:hover {
      &:before {
        content: "";
        opacity: 1;
      }
    }
    &.custom-card-prod-struc{
      .card-img-container{
        float: left;
        margin-right: 30px;
      }
      .card-title-container{
        height: 110px;
        h2{
          color: #253365;
          font-family: "Public Sans";
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: 26px;
          text-align: left;
          padding: 0;
        }
      }
    }
  }
}



#tabs {
  .tabs {
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: right;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    li {
      display: inline-block;
      cursor: pointer;
      padding: 0 15px;
      color: #253365;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: "";
        height: 23px;
        width: 1px;
        display: inline-block;
        left: 0px;
        position: absolute;
        background-color: $blue3;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
      &.current {
        span {
          opacity: 1;
        }
      }
      span {
        opacity: 0.4;
      }
    }
  }

  .tab-content {
    display: none;
  }

  .tab-content.current {
    display: inherit;
  }
}





