/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  transition: all ease 0.3s;

  &.hide {
    transform: translateY(-89px);
    transition: all ease 0.3s;
  }

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    @include media-breakpoint-down(xl) {
      padding-top: 15px;
      padding-bottom: 13px;
    }

    .item-logo {
      svg {
        path, polygon {
          //fill: $black;
        }
      }

      img {
        @include media-breakpoint-down(xl) {
          height: 60px;
        }
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        .nav-drop {
          display: none;
        }

        li {
          &:not(:last-child) {
            margin-right: 50px;
            @include media-breakpoint-down(xl) {
              margin-right: 26px;
            }
          }

          .item-link {
            color: #253365;
            font-family: "Public Sans";
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: 21px;
            text-align: left;
            transition: all ease 0.3s;

            &:hover {
              color: $orange;
              border-bottom: 1px solid $orange;
              transition: all ease 0.3s;
            }
          }

          &.btn_newsletter {
            background-color: #253365;
            border: 1px solid #253365;

            &:hover {
              background-color: initial;

              a {
                color: #253365;
              }
            }

            a {
              color: #ffffff;
              padding: 8px 26px;
              z-index: 1;
              @include media-breakpoint-down(xl) {
                padding: 8px 13px;
              }
            }
          }
        }

      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
          display: flex;
          vertical-align: middle;
          align-items: center;
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $very-dark-grey;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .menu-solution {
    position: relative;
    margin-right: 80px !important;
    @include media-breakpoint-down(xl) {
      margin-right: 50px !important;
    }

    &:after {
      content: "";
      background-image: url('../img/icon/arr-blue.svg');
      background-size: contain;
      background-position: 0;
      background-repeat: no-repeat;
      height: 10px;
      width: 15px;
      display: block;
      position: absolute;
      top: 8px;
      right: -27px;
      transition: all ease 0.3s;
      transform: rotate(0deg);
      cursor: pointer;
    }

  }

  .sub-menu-solution {
    display: none;
    height: initial !important;
    @include media-breakpoint-down(md) {
      display: none !important;
    }

    a {
      color: $blue3;
      font-family: "Public Sans";
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      transition: all ease 0.3s;
      border-bottom: 1px solid #fff;

      &:hover {
        color: #ff993a;
        border-bottom: 1px solid #ff993a;
        transition: all ease 0.3s;
      }
    }

    .desc {
      color: #253365;
      font-family: "Public Sans";
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          color: $blue3;
          font-size: 24px;
          line-height: 30px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
        }
      }

      .nav-drop {
        padding: 20px 20px 10px 20px;

        li {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }

  .btn_newsletter {
    padding-left: 30px;

    &::before {
      content: "";
      background-image: url('../img/icon/arr.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      width: 24px;
      height: 18px;
      display: block;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      transition: all ease 0.3s;
      top: 8px;
      left: 0px;
    }
  }
}

#modnews {
  max-width: 850px !important;
  height: 420px !important;

  .close {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }

  .block-newsletter {
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin: inherit;
    top: 0;
    bottom: 0;
    height: 420px;

    h2 {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 40px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 44px;
      text-align: center;
      @include media-breakpoint-down(xs) {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 0px;
      }
    }

    p {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 20px;
      text-align: center;
    }
  }
}