@charset "UTF-8";
/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container, #mobile-menu .item-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 1380px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom, #page-home .banner {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1199.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1199.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1199.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1199.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1199.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1199.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 24px; }
  @media (max-width: 1199.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 30px; }
  @media (max-width: 1199.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }

.title-xl {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1199.98px) {
    .title-xl {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 575.98px) {
    .title-xl {
      font-size: 32px; } }

.title-xxl {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 62px; }
  @media (max-width: 1199.98px) {
    .title-xxl {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xxl {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xxl {
      font-size: 36px; } }
  @media (max-width: 575.98px) {
    .title-xxl {
      font-size: 36px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Public Sans", sans-serif ; }

.ff-custom {
  font-family: "graphik", sans-serif ; }

.ff-extra {
  font-family: "canela", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-blue1 {
  color: #0587fc ; }

.c-blue2 {
  color: #105a9d ; }

.c-blue3 {
  color: #253365 ; }

.c-orange {
  color: #ff993a ; }

.c-green {
  color: #18df95 ; }

.c-very-dark-grey {
  color: #141414 ; }

.c-dark-grey {
  color: #424242 ; }

.c-grey {
  color: #999 ; }

.c-light-grey {
  color: #f5f5f5 ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #FFFFFF ; }

/*------- LINKS -------*/
.link-white {
  color: #FFFFFF; }
  .link-white:hover {
    color: #f5f5f5; }

.links-white a {
  color: #FFFFFF; }
  .links-white a:hover {
    color: #f5f5f5; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #999; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #999; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "Public Sans", sans-serif;
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  background: #FFFFFF;
  color: #141414;
  border: 1px solid #f5f5f5;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #141414; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFFFFF; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-blue1 {
  background-color: #0587fc ; }

.bg-blue2 {
  background-color: #105a9d ; }

.bg-blue3 {
  background-color: #253365 ; }

.bg-orange {
  background-color: #ff993a ; }

.bg-green {
  background-color: #18df95 ; }

.bg-very-dark-grey {
  background-color: #141414 ; }

.bg-dark-grey {
  background-color: #424242 ; }

.bg-grey {
  background-color: #999 ; }

.bg-light-grey {
  background-color: #f5f5f5 ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #FFFFFF ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1199.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1200px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1200px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1200px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1200px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 30px !important;
  padding-bottom: 30px !important; }
  @media (max-width: 1199.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 30px !important;
      padding-bottom: 30px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1199.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Light.eot");
  src: url("../fonts/graphik/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Light.woff2") format("woff2"), url("../fonts/graphik/Graphik-Light.woff") format("woff"), url("../fonts/graphik/Graphik-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Regular.eot");
  src: url("../fonts/graphik/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Regular.woff2") format("woff2"), url("../fonts/graphik/Graphik-Regular.woff") format("woff"), url("../fonts/graphik/Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Medium.eot");
  src: url("../fonts/graphik/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Medium.woff2") format("woff2"), url("../fonts/graphik/Graphik-Medium.woff") format("woff"), url("../fonts/graphik/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'graphik';
  src: url("../fonts/graphik/Graphik-Bold.eot");
  src: url("../fonts/graphik/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Bold.woff2") format("woff2"), url("../fonts/graphik/Graphik-Bold.woff") format("woff"), url("../fonts/graphik/Graphik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Regular.otf") format("opentype"), url("../fonts/canela/Canela-Regular.woff") format("woff"), url("../fonts/canela/Canela-Regular.ttf") format("truetype"), url("../fonts/canela/Canela-Regular.svg#Canela-Regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Thin.otf") format("opentype"), url("../fonts/canela/Canela-Thin.woff") format("woff"), url("../fonts/canela/Canela-Thin.ttf") format("truetype"), url("../fonts/canela/Canela-Thin.svg#Canela-Thin") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'canela';
  src: url("../fonts/canela/Canela-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/canela/Canela-Light.otf") format("opentype"), url("../fonts/canela/Canela-Light.woff") format("woff"), url("../fonts/canela/Canela-Light.ttf") format("truetype"), url("../fonts/canela/Canela-Light.svg#Canela-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Public Sans';
  src: url("../fonts/publicsans/PublicSans-SemiBold.eot");
  src: url("../fonts/publicsans/PublicSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/publicsans/PublicSans-SemiBold.woff2") format("woff2"), url("../fonts/publicsans/PublicSans-SemiBold.woff") format("woff"), url("../fonts/publicsans/PublicSans-SemiBold.ttf") format("truetype"), url("../fonts/publicsans/PublicSans-SemiBold.svg#PublicSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Public Sans';
  src: url("../fonts/publicsans/PublicSans-Light.eot");
  src: url("../fonts/publicsans/PublicSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/publicsans/PublicSans-Light.woff2") format("woff2"), url("../fonts/publicsans/PublicSans-Light.woff") format("woff"), url("../fonts/publicsans/PublicSans-Light.ttf") format("truetype"), url("../fonts/publicsans/PublicSans-Light.svg#PublicSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Public Sans';
  src: url("../fonts/publicsans/PublicSans-Bold.eot");
  src: url("../fonts/publicsans/PublicSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/publicsans/PublicSans-Bold.woff2") format("woff2"), url("../fonts/publicsans/PublicSans-Bold.woff") format("woff"), url("../fonts/publicsans/PublicSans-Bold.ttf") format("truetype"), url("../fonts/publicsans/PublicSans-Bold.svg#PublicSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Public Sans';
  src: url("../fonts/publicsans/PublicSans-Medium.eot");
  src: url("../fonts/publicsans/PublicSans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/publicsans/PublicSans-Medium.woff2") format("woff2"), url("../fonts/publicsans/PublicSans-Medium.woff") format("woff"), url("../fonts/publicsans/PublicSans-Medium.ttf") format("truetype"), url("../fonts/publicsans/PublicSans-Medium.svg#PublicSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  color: #253365;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  background-color: #f5f5f5; }

#page-news-single,
#page-comprendre {
  background-color: #FFFFFF; }

h1 {
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 50px;
  line-height: initial;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: left; }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 34px;
      line-height: initial; } }

h2 {
  color: #253365;
  font-family: "Public Sans";
  font-size: 40px;
  line-height: 44px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 11px; }
  h2.line {
    border-bottom: 1px solid #253365;
    width: 100%; }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 28px;
      line-height: 32px; } }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center; }

img {
  max-width: initial; }

a, img, span, button {
  display: inline-block; }

a {
  color: #253365;
  text-decoration: none; }
  a:hover {
    color: #253365; }

.arr-b {
  float: right; }
  .arr-b:after {
    content: "";
    background-image: url("../img/icon/arr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 4px 0px 0 8px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s; }

.mobi-disp {
  display: none !important; }
  @media (max-width: 991.98px) {
    .mobi-disp {
      display: block !important; } }

.desk-disp {
  display: block !important; }
  @media (max-width: 991.98px) {
    .desk-disp {
      display: none !important; } }

.desk-disp-flex {
  display: flex !important; }
  @media (max-width: 991.98px) {
    .desk-disp-flex {
      display: none !important; } }

.nopad {
  padding-left: 0;
  padding-right: 0; }

.z1 {
  z-index: 1; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.flex {
  display: flex;
  vertical-align: middle;
  align-items: center; }

.dflex {
  display: flex; }

.txt-c {
  text-align: center !important; }

.txt-r {
  text-align: right !important; }

.rel {
  position: relative; }

/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
.link-menu, #header .lang-container {
  text-transform: uppercase;
  color: #141414;
  font-size: 14px;
  font-weight: 400; }

#header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  transition: all ease 0.3s; }
  #header.hide {
    transform: translateY(-89px);
    transition: all ease 0.3s; }
  #header .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px; }
    @media (max-width: 1399.98px) {
      #header .header-container {
        padding-top: 15px;
        padding-bottom: 13px; } }
    @media (max-width: 1399.98px) {
      #header .header-container .item-logo img {
        height: 60px; } }
    #header .header-container .item-nav {
      display: none; }
      #header .header-container .item-nav .item-menu {
        display: flex; }
        #header .header-container .item-nav .item-menu .nav-drop {
          display: none; }
        #header .header-container .item-nav .item-menu li:not(:last-child) {
          margin-right: 50px; }
          @media (max-width: 1399.98px) {
            #header .header-container .item-nav .item-menu li:not(:last-child) {
              margin-right: 26px; } }
        #header .header-container .item-nav .item-menu li .item-link {
          color: #253365;
          font-family: "Public Sans";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: 21px;
          text-align: left;
          transition: all ease 0.3s; }
          #header .header-container .item-nav .item-menu li .item-link:hover {
            color: #ff993a;
            border-bottom: 1px solid #ff993a;
            transition: all ease 0.3s; }
        #header .header-container .item-nav .item-menu li.btn_newsletter {
          background-color: #253365;
          border: 1px solid #253365; }
          #header .header-container .item-nav .item-menu li.btn_newsletter:hover {
            background-color: initial; }
            #header .header-container .item-nav .item-menu li.btn_newsletter:hover a {
              color: #253365; }
          #header .header-container .item-nav .item-menu li.btn_newsletter a {
            color: #ffffff;
            padding: 8px 26px;
            z-index: 1; }
            @media (max-width: 1399.98px) {
              #header .header-container .item-nav .item-menu li.btn_newsletter a {
                padding: 8px 13px; } }
      @media (min-width: 1200px) {
        #header .header-container .item-nav {
          display: flex;
          align-items: center; }
          #header .header-container .item-nav .item-menu {
            margin-right: 25px;
            display: flex;
            vertical-align: middle;
            align-items: center; } }
  #header .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    cursor: pointer; }
    #header .lang-container ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 2s cubic-bezier(0.19, 1, 0.22, 1); }
    #header .lang-container:hover ul {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateX(0) !important; }
  #header .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer; }
    #header .btn-menu > .item-burger {
      display: block;
      width: 25px; }
      #header .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #141414;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2) {
          margin: 6px 0; }
    @media (min-width: 1200px) {
      #header .btn-menu {
        display: none; } }
  #header .menu-solution {
    position: relative;
    margin-right: 80px !important; }
    @media (max-width: 1399.98px) {
      #header .menu-solution {
        margin-right: 50px !important; } }
    #header .menu-solution:after {
      content: "";
      background-image: url("../img/icon/arr-blue.svg");
      background-size: contain;
      background-position: 0;
      background-repeat: no-repeat;
      height: 10px;
      width: 15px;
      display: block;
      position: absolute;
      top: 8px;
      right: -27px;
      transition: all ease 0.3s;
      transform: rotate(0deg);
      cursor: pointer; }
  #header .sub-menu-solution {
    display: none;
    height: initial !important; }
    @media (max-width: 991.98px) {
      #header .sub-menu-solution {
        display: none !important; } }
    #header .sub-menu-solution a {
      color: #253365;
      font-family: "Public Sans";
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
      transition: all ease 0.3s;
      border-bottom: 1px solid #fff; }
      #header .sub-menu-solution a:hover {
        color: #ff993a;
        border-bottom: 1px solid #ff993a;
        transition: all ease 0.3s; }
    #header .sub-menu-solution .desc {
      color: #253365;
      font-family: "Public Sans";
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left; }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  overflow: auto;
  opacity: 0;
  visibility: hidden; }
  #mobile-menu .item-container ul > li:not(:last-child) {
    margin-bottom: 20px; }
  #mobile-menu .item-container ul > li a {
    color: #253365;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased; }
    @media (max-width: 575.98px) {
      #mobile-menu .item-container ul > li a {
        font-size: 26px; } }
  #mobile-menu .item-container ul .nav-drop {
    padding: 20px 20px 10px 20px; }
    #mobile-menu .item-container ul .nav-drop li a {
      font-size: 18px; }
  #mobile-menu .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    #mobile-menu .item-socials-container .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px; }
      #mobile-menu .item-socials-container .item-socials a {
        font-size: 25px; }
        #mobile-menu .item-socials-container .item-socials a:not(:last-child) {
          margin-right: 25px; }
      @media (max-width: 575.98px) {
        #mobile-menu .item-socials-container .item-socials {
          margin-bottom: 15px; }
          #mobile-menu .item-socials-container .item-socials a {
            font-size: 20px; } }
  #mobile-menu .btn_newsletter {
    padding-left: 30px; }
    #mobile-menu .btn_newsletter::before {
      content: "";
      background-image: url("../img/icon/arr.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      width: 24px;
      height: 18px;
      display: block;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      transition: all ease 0.3s;
      top: 8px;
      left: 0px; }

#modnews {
  max-width: 850px !important;
  height: 420px !important; }
  #modnews .close {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    cursor: pointer; }
  #modnews .block-newsletter {
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin: inherit;
    top: 0;
    bottom: 0;
    height: 420px; }
    #modnews .block-newsletter h2 {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 40px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 44px;
      text-align: center; }
      @media (max-width: 575.98px) {
        #modnews .block-newsletter h2 {
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 0px; } }
    #modnews .block-newsletter p {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 20px;
      text-align: center; }

/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 50px 0 20px 0px; }
  @media (max-width: 1199.98px) {
    #footer {
      text-align: center; } }
  @media (max-width: 1199.98px) {
    #footer .item-logo {
      margin-bottom: 15px; } }
  #footer a:hover {
    text-decoration: underline; }
  #footer .contact-form .gform_wrapper {
    margin: 0; }
    #footer .contact-form .gform_wrapper .gform_anchor {
      display: none; }
    #footer .contact-form .gform_wrapper form {
      position: relative;
      max-width: 470px;
      width: 100%;
      margin: 0 auto; }
      #footer .contact-form .gform_wrapper form .gform_body {
        margin: 0; }
        #footer .contact-form .gform_wrapper form .gform_body #field_1_1 {
          margin: 0; }
          @media (max-width: 767.98px) {
            #footer .contact-form .gform_wrapper form .gform_body #field_1_1 {
              padding: 0; } }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_1 label {
            color: #253365;
            font-family: "Public Sans";
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 20px;
            text-align: left;
            margin: 0;
            padding-bottom: 10px; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input {
            border: 1px solid #253365;
            width: 100%;
            padding: 10px 18px;
            margin: 0; }
            @media (max-width: 767.98px) {
              #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input {
                padding: 10px 8px !important; } }
            @media (max-width: 575.98px) {
              #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input {
                padding: 6px 8px !important; } }
            #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input::-webkit-input-placeholder {
              color: #a2a2a2; }
            #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input::-moz-placeholder {
              color: #a2a2a2; }
            #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input:-ms-input-placeholder {
              color: #a2a2a2; }
            #footer .contact-form .gform_wrapper form .gform_body #field_1_1 input:-moz-placeholder {
              color: #a2a2a2; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_1 span {
            display: none; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_1.gfield_error #input_1_1 {
            border: 1px solid #790000 !important; }
        #footer .contact-form .gform_wrapper form .gform_body #field_1_2 {
          padding: 0;
          margin: 0; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_2 .gfield_label {
            display: none; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_2 input {
            padding: 0;
            margin: 0; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_2 #input_1_2_1 {
            -webkit-appearance: radio;
            float: left;
            width: 22px;
            margin-top: 9px;
            color: #253365;
            font-family: "Public Sans";
            font-size: 12px;
            font-weight: 300;
            font-style: normal;
            letter-spacing: normal;
            line-height: 20px;
            text-align: left; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_2 label.gfield_consent_label {
            color: #253365;
            font-family: "Public Sans";
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 18px;
            text-align: left;
            margin-top: 7px; }
            #footer .contact-form .gform_wrapper form .gform_body #field_1_2 label.gfield_consent_label a {
              color: #253365; }
              #footer .contact-form .gform_wrapper form .gform_body #field_1_2 label.gfield_consent_label a:hover {
                text-decoration: underline; }
          #footer .contact-form .gform_wrapper form .gform_body #field_1_2.gfield_error .gfield_consent_label {
            color: red; }
        #footer .contact-form .gform_wrapper form .gform_body .ginput_container {
          margin-top: 0px;
          display: flex; }
        #footer .contact-form .gform_wrapper form .gform_body .gfield.gfield_error {
          background: none;
          margin-bottom: 0 !important;
          border: none;
          padding: 0; }
          #footer .contact-form .gform_wrapper form .gform_body .gfield.gfield_error .validation_message {
            padding: 0;
            font-size: 12px;
            font-weight: 400; }
      #footer .contact-form .gform_wrapper form .gform_footer {
        padding: 0px;
        margin: 0;
        clear: both;
        width: inherit;
        position: absolute;
        top: 0;
        right: 30px;
        border: none; }
        #footer .contact-form .gform_wrapper form .gform_footer .btn_arrow {
          content: "";
          background-image: url("../img/icon/arr.svg");
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: center;
          background-color: transparent;
          width: 40px;
          height: 44px;
          display: block;
          cursor: pointer;
          margin: 0 0px 0 0;
          position: absolute;
          top: 33px;
          left: initial;
          right: -13px;
          z-index: 1; }
          @media (max-width: 767.98px) {
            #footer .contact-form .gform_wrapper form .gform_footer .btn_arrow {
              right: -29px; } }
      #footer .contact-form .gform_wrapper form .validation_error {
        display: none; }
      #footer .contact-form .gform_wrapper form #gform_ajax_spinner_1 {
        display: block;
        position: absolute;
        right: -10px;
        top: -15px; }
  #footer .contact-form .gform_confirmation_message {
    color: green; }
  #footer .cms {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left; }
    @media (max-width: 1199.98px) {
      #footer .cms {
        text-align: center; } }
    #footer .cms ul li,
    #footer .cms ul li a {
      color: #253365;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left; }
      @media (max-width: 1199.98px) {
        #footer .cms ul li,
        #footer .cms ul li a {
          text-align: center; } }
  #footer .copyright {
    border-top: 1px solid #c3c3c3;
    padding-top: 20px;
    padding-bottom: 2px; }
  #footer .copyright, #footer .copyright a {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: center; }

/*
|--------------------
|      CONTENT
|--------------------
*/
/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  height: 470px;
  transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);
  /*
  * Card img
  */ }
  .custom-card:hover {
    transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1); }
  .custom-card:hover .link-card:before {
    content: "";
    height: 40px;
    width: 40px;
    opacity: 1;
    transition: all ease 0.3s; }
  @media (max-width: 1399.98px) {
    .custom-card {
      height: 520px; } }
  @media (max-width: 767.98px) {
    .custom-card {
      height: initial; } }
  .custom-card .custom-card-link {
    display: block; }
  .custom-card .card-img-container {
    height: 275px;
    position: relative;
    overflow: hidden; }
    .custom-card .card-img-container:after {
      content: '';
      display: block;
      padding-bottom: 100%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
    .custom-card .card-img-container .label {
      position: absolute;
      top: 15px;
      right: 15px;
      height: 53px;
      width: 53px;
      display: block;
      z-index: 1; }

.card-content:hover .card-img-container .card-img {
  transform: scale(1.05); }

.card-content h2 {
  color: #253365;
  font-family: "Public Sans";
  font-size: 19px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left; }
  @media (max-width: 991.98px) {
    .card-content h2 {
      font-size: 16px;
      line-height: 22px; } }

.card-content .date-card {
  color: #253365;
  font-family: "Public Sans";
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left; }
  @media (max-width: 1199.98px) {
    .card-content .date-card {
      font-size: 13px;
      display: contents; } }

.card-content .tag-card {
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase; }
  @media (max-width: 1199.98px) {
    .card-content .tag-card {
      font-size: 13px;
      display: contents; } }

.card-content .author-card {
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase; }

.card-content .link-card {
  color: #253365;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  position: absolute;
  bottom: 15px;
  left: 30px; }
  @media (max-width: 767.98px) {
    .card-content .link-card {
      margin: 20px 0 0 0 !important;
      padding: 0 !important;
      left: 0;
      right: 0;
      bottom: 0;
      display: block; } }
  .card-content .link-card:before {
    content: "";
    height: 0;
    width: 0;
    border: 1px solid #253365;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: -90px;
    left: 0;
    bottom: 0;
    float: right;
    transition: all ease 0.3s;
    margin: auto;
    opacity: 0; }
    @media (max-width: 767.98px) {
      .card-content .link-card:before {
        right: -9px;
        left: initial; } }
  .card-content .link-card:hover:before {
    content: "";
    height: 40px;
    width: 40px;
    opacity: 1;
    transition: all ease 0.3s; }
  .card-content .link-card:after {
    content: "";
    background-image: url("../img/icon/arr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 3px 0px 0 17px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s; }
  @media (max-width: 767.98px) {
    .card-content .link-card {
      position: relative; } }

.term-card {
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 15px;
  padding: 2px 15px;
  margin-right: 5px; }
  @media (max-width: 1399.98px) {
    .term-card {
      font-size: 10px;
      padding: 2px 8px;
      display: inline-block; } }
  .term-card:hover {
    color: white; }
  .term-card.term-social {
    background-color: #ff993a; }
  .term-card.term-energie, .term-card.term-environnement {
    background-color: #18df95; }
  .term-card.term-gouvernance {
    background-color: #0587fc; }

.firstcard .custom-card {
  height: 421px; }
  @media (max-width: 1399.98px) {
    .firstcard .custom-card {
      height: 520px; } }
  @media (max-width: 991.98px) {
    .firstcard .custom-card {
      height: initial; } }
  .firstcard .custom-card:hover .link-card:before {
    content: "";
    height: 40px;
    width: 40px;
    opacity: 1;
    transition: all ease 0.3s; }
  .firstcard .custom-card .custom-card-link {
    display: block; }
  .firstcard .custom-card .card-img-container {
    height: 421px;
    position: relative;
    overflow: hidden; }
    @media (max-width: 1399.98px) {
      .firstcard .custom-card .card-img-container {
        height: 520px; } }
    @media (max-width: 991.98px) {
      .firstcard .custom-card .card-img-container {
        height: 275px; } }
  .firstcard .custom-card h2 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 30px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 34px;
    text-align: left; }
    @media (max-width: 767.98px) {
      .firstcard .custom-card h2 {
        font-size: 19px;
        line-height: 26px; } }
  .firstcard .custom-card .content-card {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20px;
    text-align: left; }
    @media (max-width: 767.98px) {
      .firstcard .custom-card .content-card {
        display: none; } }
  .firstcard .custom-card .link-card {
    position: relative;
    left: 0px; }

.firstcard .flex-card {
  vertical-align: middle;
  display: flex;
  align-items: center; }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #999; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #999; }

/*
|
| CMS
|------
*/
.cms span {
  display: inline; }

.cms h1, .cms h2, .cms h3, .cms h4, .cms h5, .cms h6 {
  margin-bottom: 20px; }

.cms h1 {
  color: #253365;
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px; }

.cms h2 {
  color: #253365;
  font-family: "Public Sans";
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px; }

.cms img {
  display: block;
  max-width: 100%;
  height: auto; }

.cms strong {
  font-weight: 800; }

.cms p {
  margin-bottom: 20px; }

.cms ul {
  margin: 20px;
  padding: 0; }
  .cms ul li {
    list-style: disc; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  color: #253365;
  font-family: "Public Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  margin: 40px 0px 40px 70px; }
  @media (max-width: 991.98px) {
    .cms blockquote {
      margin: 25px; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    border-left: 10px solid #253365;
    width: 100%;
    height: 100%;
    margin-left: -25px; }

.cms .desc {
  color: #253365;
  font-family: "Public Sans";
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left; }
  .cms .desc p {
    font-size: 16px;
    line-height: 23px; }

.link-arrow {
  color: #253365;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left; }
  .link-arrow:after {
    content: "";
    background-image: url("../img/icon/arr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 4px 0px 0 8px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s; }
  .link-arrow:hover:after {
    content: "";
    margin: 4px 0px 0 14px;
    transition: all ease 0.3s; }

.card-image .legend {
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  float: right; }
  @media (max-width: 991.98px) {
    .card-image .legend {
      float: left; } }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 89px; }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

.form-nl .gform_wrapper {
  margin: 0; }
  .form-nl .gform_wrapper .gform_anchor {
    display: none; }
  .form-nl .gform_wrapper form {
    position: relative;
    max-width: 470px;
    width: 100%;
    margin: 0 auto; }
    .form-nl .gform_wrapper form .gform_body {
      margin: 0; }
      .form-nl .gform_wrapper form .gform_body #field_2_1,
      .form-nl .gform_wrapper form .gform_body #field_3_1 {
        margin: 0; }
        @media (max-width: 767.98px) {
          .form-nl .gform_wrapper form .gform_body #field_2_1,
          .form-nl .gform_wrapper form .gform_body #field_3_1 {
            padding-right: 0; } }
        .form-nl .gform_wrapper form .gform_body #field_2_1 label,
        .form-nl .gform_wrapper form .gform_body #field_3_1 label {
          color: white;
          font-family: "Public Sans";
          font-size: 16px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: normal;
          line-height: 20px;
          text-align: left;
          margin: 0;
          padding-bottom: 10px;
          display: none; }
        .form-nl .gform_wrapper form .gform_body #field_2_1 input,
        .form-nl .gform_wrapper form .gform_body #field_3_1 input {
          border: 1px solid white;
          width: 100%;
          padding: 10px 18px;
          margin: 0;
          color: white;
          min-height: 2rem; }
          @media (max-width: 767.98px) {
            .form-nl .gform_wrapper form .gform_body #field_2_1 input,
            .form-nl .gform_wrapper form .gform_body #field_3_1 input {
              padding: 10px 8px !important; } }
          @media (max-width: 575.98px) {
            .form-nl .gform_wrapper form .gform_body #field_2_1 input,
            .form-nl .gform_wrapper form .gform_body #field_3_1 input {
              padding: 6px 8px !important; } }
          .form-nl .gform_wrapper form .gform_body #field_2_1 input:hover, .form-nl .gform_wrapper form .gform_body #field_2_1 input:focus, .form-nl .gform_wrapper form .gform_body #field_2_1 input:active,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input:hover,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input:focus,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input:active {
            background: transparent !important; }
          .form-nl .gform_wrapper form .gform_body #field_2_1 input::-webkit-input-placeholder,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input::-webkit-input-placeholder {
            color: white; }
          .form-nl .gform_wrapper form .gform_body #field_2_1 input::-moz-placeholder,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input::-moz-placeholder {
            color: white; }
          .form-nl .gform_wrapper form .gform_body #field_2_1 input:-ms-input-placeholder,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input:-ms-input-placeholder {
            color: white; }
          .form-nl .gform_wrapper form .gform_body #field_2_1 input:-moz-placeholder,
          .form-nl .gform_wrapper form .gform_body #field_3_1 input:-moz-placeholder {
            color: white; }
        .form-nl .gform_wrapper form .gform_body #field_2_1 span,
        .form-nl .gform_wrapper form .gform_body #field_3_1 span {
          display: none; }
        .form-nl .gform_wrapper form .gform_body #field_2_1.gfield_error #input_2_1,
        .form-nl .gform_wrapper form .gform_body #field_2_1.gfield_error #input_3_1,
        .form-nl .gform_wrapper form .gform_body #field_3_1.gfield_error #input_2_1,
        .form-nl .gform_wrapper form .gform_body #field_3_1.gfield_error #input_3_1 {
          border: 1px solid #e4b1b1 !important; }
      .form-nl .gform_wrapper form .gform_body #field_2_2,
      .form-nl .gform_wrapper form .gform_body #field_3_2 {
        padding: 0;
        margin: 0; }
        .form-nl .gform_wrapper form .gform_body #field_2_2 .gfield_label,
        .form-nl .gform_wrapper form .gform_body #field_3_2 .gfield_label {
          display: none; }
        .form-nl .gform_wrapper form .gform_body #field_2_2 input,
        .form-nl .gform_wrapper form .gform_body #field_3_2 input {
          padding: 0;
          margin: 0; }
        .form-nl .gform_wrapper form .gform_body #field_2_2 #input_2_2_1,
        .form-nl .gform_wrapper form .gform_body #field_2_2 #input_3_2_1,
        .form-nl .gform_wrapper form .gform_body #field_3_2 #input_2_2_1,
        .form-nl .gform_wrapper form .gform_body #field_3_2 #input_3_2_1 {
          -webkit-appearance: radio;
          float: left;
          width: 22px;
          margin-top: 9px;
          color: white;
          font-family: "Public Sans";
          font-size: 12px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: 20px;
          text-align: left; }
        .form-nl .gform_wrapper form .gform_body #field_2_2 label.gfield_consent_label,
        .form-nl .gform_wrapper form .gform_body #field_3_2 label.gfield_consent_label {
          color: white;
          font-family: "Public Sans";
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: normal;
          line-height: 18px;
          text-align: left;
          margin-top: 7px; }
          .form-nl .gform_wrapper form .gform_body #field_2_2 label.gfield_consent_label a,
          .form-nl .gform_wrapper form .gform_body #field_3_2 label.gfield_consent_label a {
            color: white; }
            .form-nl .gform_wrapper form .gform_body #field_2_2 label.gfield_consent_label a:hover,
            .form-nl .gform_wrapper form .gform_body #field_3_2 label.gfield_consent_label a:hover {
              text-decoration: underline; }
        .form-nl .gform_wrapper form .gform_body #field_2_2.gfield_error .gfield_consent_label,
        .form-nl .gform_wrapper form .gform_body #field_3_2.gfield_error .gfield_consent_label {
          color: #e4b1b1; }
      .form-nl .gform_wrapper form .gform_body .ginput_container {
        margin-top: 0px;
        display: flex; }
      .form-nl .gform_wrapper form .gform_body .gfield.gfield_error {
        background: none;
        margin-bottom: 0 !important;
        border: none;
        padding: 0; }
        .form-nl .gform_wrapper form .gform_body .gfield.gfield_error .validation_message {
          padding: 0;
          font-size: 12px;
          font-weight: 400;
          color: #e4b1b1; }
    .form-nl .gform_wrapper form .gform_footer {
      padding: 0px;
      margin: 0;
      clear: both;
      width: inherit;
      position: absolute;
      top: 0;
      right: 30px;
      border: none; }
      .form-nl .gform_wrapper form .gform_footer .btn_arrow {
        content: "";
        background-image: url("../img/icon/arr-w.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center;
        background-color: #253365;
        width: 40px;
        height: 44px;
        display: block;
        cursor: pointer;
        margin: 0;
        position: absolute;
        top: 1px;
        left: initial;
        right: -13px;
        z-index: 1; }
        @media (max-width: 767.98px) {
          .form-nl .gform_wrapper form .gform_footer .btn_arrow {
            right: -29px; } }
    .form-nl .gform_wrapper form .validation_error {
      display: none; }

.form-nl .gform_confirmation_message {
  color: green; }

.form-nl #gform_ajax_spinner_2,
.form-nl #gform_ajax_spinner_3 {
  display: block;
  position: absolute;
  right: -15px;
  top: 50px;
  height: 50px;
  width: 50px; }
  .form-nl #gform_ajax_spinner_2 img,
  .form-nl #gform_ajax_spinner_3 img {
    height: 50px;
    width: 50px; }

.banner-intro {
  height: 322px;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #253365;
  color: #ffffff;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  display: flex;
  vertical-align: middle;
  align-items: center; }
  .banner-intro a {
    color: white; }

.prefooter-block.block-newsletter {
  color: white; }
  .prefooter-block.block-newsletter h2 {
    color: white;
    text-align: center; }

#gform_confirmation_message_2,
#gform_confirmation_message_3 {
  color: white; }

.prefooter-block.prefooter {
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center; }

/*
|
| FAQ
|---------------
*/
.block-accordion {
  position: relative; }
  .block-accordion h2, .block-accordion h3 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left;
    width: 100%; }
  .block-accordion input[type="text"] {
    position: absolute;
    top: 0;
    right: 15px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #253365;
    height: 50px;
    padding-left: 50px;
    background-image: url("/wp-content/themes/yagami-adveris/web/src/img/icon/search.svg");
    background-repeat: no-repeat;
    background-position: 15px center; }
    @media (max-width: 767.98px) {
      .block-accordion input[type="text"] {
        position: relative;
        width: 100%;
        left: inherit;
        right: initial; } }
  .block-accordion .accordion-item.hide,
  .block-accordion .groupe.hide {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none;
    border: none; }
  .block-accordion h2 {
    padding: 0 0 1em 0;
    border-bottom: 1px solid #253365; }
  .block-accordion h3 {
    padding: 1em 1.5em 1em 0;
    border-bottom: 1px solid #253365; }
  .block-accordion .accordion .accordion-item {
    border-bottom: 1px solid #253365;
    opacity: 1;
    visibility: visible;
    transition: visibility .2s ease-out, opacity .2s ease-out; }
    .block-accordion .accordion .accordion-item button {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 1em 0;
      font-size: 1.15rem;
      font-weight: 300;
      border: none;
      background: none;
      outline: none; }
      .block-accordion .accordion .accordion-item button:hover, .block-accordion .accordion .accordion-item button:focus {
        cursor: pointer;
        color: #253365;
        font-weight: 700; }
        .block-accordion .accordion .accordion-item button:hover::after, .block-accordion .accordion .accordion-item button:focus::after {
          cursor: pointer;
          color: #253365;
          border: 1px solid #253365; }
      .block-accordion .accordion .accordion-item button .accordion-title {
        padding: 1em 1.5em 1em 0;
        color: #253365;
        font-family: "Public Sans";
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left;
        width: calc(100% - 60px); }
      .block-accordion .accordion .accordion-item button .icon {
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 0;
        width: 56px;
        height: 56px;
        border: 1px solid #253365;
        border-radius: 50%; }
        .block-accordion .accordion .accordion-item button .icon::before {
          content: "";
          background-image: url("../img/icon/arr.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          width: 24px;
          height: 18px;
          display: block;
          cursor: pointer;
          z-index: 1;
          position: absolute;
          transition: all ease 0.3s;
          top: 18px;
          left: 14px; }
    .block-accordion .accordion .accordion-item button[aria-expanded='true'], .block-accordion .accordion .accordion-item:hover {
      color: #253365; }
      .block-accordion .accordion .accordion-item button[aria-expanded='true'] .icon::after, .block-accordion .accordion .accordion-item:hover .icon::after {
        width: 0; }
      .block-accordion .accordion .accordion-item button[aria-expanded='true'] + .accordion-content, .block-accordion .accordion .accordion-item:hover + .accordion-content {
        opacity: 1;
        max-height: inherit;
        transition: all 200ms linear;
        will-change: opacity, max-height; }
        @media (max-width: 991.98px) {
          .block-accordion .accordion .accordion-item button[aria-expanded='true'] + .accordion-content, .block-accordion .accordion .accordion-item:hover + .accordion-content {
            max-height: inherit; } }
      .block-accordion .accordion .accordion-item button[aria-expanded='true'] .accordion-title, .block-accordion .accordion .accordion-item:hover .accordion-title {
        font-weight: 600;
        transition: all ease 0.3s; }
      .block-accordion .accordion .accordion-item button[aria-expanded='true'] .icon, .block-accordion .accordion .accordion-item:hover .icon {
        background-color: #253365; }
        .block-accordion .accordion .accordion-item button[aria-expanded='true'] .icon::before, .block-accordion .accordion .accordion-item:hover .icon::before {
          content: "";
          background-image: url("../img/icon/arr-w.svg"); }
    .block-accordion .accordion .accordion-item button[aria-expanded='true'] .icon::before {
      content: "";
      transform: rotate(90deg); }
    .block-accordion .accordion .accordion-item .accordion-content {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity 200ms linear, max-height 200ms linear;
      will-change: opacity, max-height; }
      .block-accordion .accordion .accordion-item .accordion-content p {
        font-size: 1rem;
        font-weight: 300;
        margin: 10px 0 30px; }
  .block-accordion .accordion ul {
    padding-left: 7px; }
    .block-accordion .accordion ul li {
      position: relative;
      padding-left: 10px; }
      .block-accordion .accordion ul li:before {
        content: "";
        height: 3px;
        width: 3px;
        display: block;
        position: absolute;
        left: 0px;
        top: 10px;
        background-color: #000;
        border-radius: 50%; }

/*
|
| A propos
|---------------
*/
#page-apropos {
  padding-top: 89px; }
  #page-apropos .banner-intro {
    background-position: left; }
  #page-apropos #swiper-container {
    overflow: hidden; }
    #page-apropos #swiper-container .swiper-wrapper .swiper-slide {
      position: relative;
      margin: auto; }
      #page-apropos #swiper-container .swiper-wrapper .swiper-slide .block-img {
        display: flex;
        text-align: center;
        vertical-align: middle;
        align-items: center;
        margin: auto;
        height: 60px; }
        #page-apropos #swiper-container .swiper-wrapper .swiper-slide .block-img img {
          max-width: 60%;
          max-height: 60px;
          display: block;
          margin: auto;
          filter: grayscale(100%);
          transition: all ease 0.4s; }
          @media (max-width: 991.98px) {
            #page-apropos #swiper-container .swiper-wrapper .swiper-slide .block-img img {
              max-height: 60px; } }
      #page-apropos #swiper-container .swiper-wrapper .swiper-slide a {
        display: block;
        color: #253365;
        font-family: "Public Sans";
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: 20px;
        text-decoration: underline;
        text-align: center; }
      #page-apropos #swiper-container .swiper-wrapper .swiper-slide:hover img {
        filter: initial;
        transition: all ease 0.4s; }
  #page-apropos .swiper-button-prev {
    outline: none;
    left: 20px;
    right: inherit;
    z-index: 9 !important; }
  #page-apropos .swiper-button-next {
    outline: none;
    left: inherit;
    right: 20px;
    z-index: 9 !important; }
  #page-apropos .swiper-button-prev:after, #page-apropos .swiper-button-next:after {
    content: "";
    background-image: url("../img/icon/arr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f5f5f5;
    background-size: 20px;
    border: 1px solid #253365;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    display: block;
    cursor: pointer;
    margin: 0px;
    position: absolute;
    bottom: 0px;
    right: -17px;
    z-index: 1;
    transform: rotate(180deg); }
  #page-apropos .swiper-button-prev:hover:after {
    content: "";
    background-image: url("../img/icon/arr-w.svg");
    background-color: #253365; }
  #page-apropos .swiper-button-next:after {
    content: "";
    left: -17px;
    right: initial;
    transform: rotate(0deg); }
  #page-apropos .swiper-button-next:hover:after {
    content: "";
    background-image: url("../img/icon/arr-w.svg");
    background-color: #253365; }
  #page-apropos #restons-en-contact h2 {
    color: #ffffff; }
  #page-apropos #restons-en-contact img {
    padding: 4px 20px;
    float: left; }
  #page-apropos #restons-en-contact ul {
    margin: 15px 0; }
    #page-apropos #restons-en-contact ul li {
      color: #ffffff;
      padding-bottom: 50px; }
      #page-apropos #restons-en-contact ul li:last-child {
        padding-bottom: 0px; }
      #page-apropos #restons-en-contact ul li a,
      #page-apropos #restons-en-contact ul li p {
        color: #fff; }
  #page-apropos #restons-en-contact:before {
    content: "";
    background-image: url("../img/global/filter_contact.png");
    height: 239px;
    width: 238px;
    position: absolute;
    left: -50px;
    bottom: -50px;
    opacity: 0.3; }

.block-left {
  height: 650px;
  margin-top: -60px !important; }
  @media (max-width: 991.98px) {
    .block-left {
      height: initial !important;
      margin-top: 0px !important; } }
  .block-left .bloc-img-left {
    position: absolute; }
    @media (max-width: 991.98px) {
      .block-left .bloc-img-left {
        position: relative; } }
    .block-left .bloc-img-left .img-left {
      height: 650px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
    .block-left .bloc-img-left img {
      max-width: 100%;
      height: inherit;
      margin: 20px auto; }
      @media (max-width: 991.98px) {
        .block-left .bloc-img-left img {
          margin: 0 auto 20px auto; } }
  .block-left .block-cont {
    height: 650px; }
    @media (max-width: 991.98px) {
      .block-left .block-cont {
        height: initial; } }

.block-left,
.block-right {
  height: 650px;
  margin-top: -60px !important; }
  @media (max-width: 991.98px) {
    .block-left,
    .block-right {
      height: initial !important;
      margin-top: 0px !important; } }
  .block-left .bloc-img-left,
  .block-left .bloc-img-right,
  .block-right .bloc-img-left,
  .block-right .bloc-img-right {
    position: absolute; }
    @media (max-width: 991.98px) {
      .block-left .bloc-img-left,
      .block-left .bloc-img-right,
      .block-right .bloc-img-left,
      .block-right .bloc-img-right {
        position: relative; } }
    .block-left .bloc-img-left .img-left,
    .block-left .bloc-img-left .img-right,
    .block-left .bloc-img-right .img-left,
    .block-left .bloc-img-right .img-right,
    .block-right .bloc-img-left .img-left,
    .block-right .bloc-img-left .img-right,
    .block-right .bloc-img-right .img-left,
    .block-right .bloc-img-right .img-right {
      height: 650px;
      background-repeat: no-repeat;
      background-position: center; }
    .block-left .bloc-img-left img,
    .block-left .bloc-img-right img,
    .block-right .bloc-img-left img,
    .block-right .bloc-img-right img {
      max-width: 100%;
      height: inherit;
      margin: 20px auto; }
      @media (max-width: 991.98px) {
        .block-left .bloc-img-left img,
        .block-left .bloc-img-right img,
        .block-right .bloc-img-left img,
        .block-right .bloc-img-right img {
          margin: 0 auto 20px auto; } }
  .block-left .block-cont,
  .block-right .block-cont {
    height: 650px; }
    @media (max-width: 991.98px) {
      .block-left .block-cont,
      .block-right .block-cont {
        height: initial; } }

.block-right {
  margin-top: 0px !important; }

/*
|
| Page CMS
|---------------
*/
.page-template-cms {
  background-color: #fff; }
  .page-template-cms #page-cms h1 {
    color: #253365; }
  .page-template-cms h2 {
    margin-top: 30px; }
  .page-template-cms p {
    margin: 15px 0; }

/*
|
| Contenu flexible
|---------------
*/
.flexible {
  padding-bottom: 30px; }
  .flexible .section_image_texte.block-left {
    height: 650px;
    margin-top: 50px !important; }
  .flexible .section_texte_image.block-right {
    height: 650px;
    margin-top: 50px !important; }
  .flexible .section_marque .image {
    width: 119px;
    height: 119px;
    min-width: 119px;
    background-color: #f5f5f5;
    border-radius: 50%;
    vertical-align: middle;
    align-items: center;
    display: flex; }
    .flexible .section_marque .image img {
      width: 100%;
      padding: 20px; }
  .flexible .section_marque .detail {
    padding: 0 0 0 20px; }
  .flexible .section_faq .accordion-item {
    padding: 20px 0 !important; }
  .flexible .section_faq .accordion-content p {
    margin: 20px 0 0px !important; }
  .flexible .section_faq button {
    padding: 0 0 !important; }
    .flexible .section_faq button .image {
      width: 61px;
      max-width: 61px;
      height: 61px;
      border-radius: 50%;
      background-size: cover;
      float: left;
      margin-right: 20px; }
      .flexible .section_faq button .image.img-def {
        background-image: url("../img/global/def-img.png"); }
    .flexible .section_faq button .icon {
      top: initial !important; }
    .flexible .section_faq button .accordion-title {
      padding: 1em 60px 1em 0 !important; }
  .flexible .section_glossaire h2 {
    margin-bottom: 0px; }
  .flexible .section_glossaire .elem {
    border-bottom: 1px solid #253365; }
    .flexible .section_glossaire .elem h3 {
      color: #253365;
      font-family: "Public Sans";
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left; }
    .flexible .section_glossaire .elem p {
      margin: 0; }
  .flexible .section_bon_a_savoir {
    color: #ffffff;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left; }
    .flexible .section_bon_a_savoir .bg {
      background-color: #253365;
      padding: 60px 90px; }
      @media (max-width: 991.98px) {
        .flexible .section_bon_a_savoir .bg {
          padding: 40px 30px; } }
      .flexible .section_bon_a_savoir .bg h3 {
        font-size: 20px; }
      .flexible .section_bon_a_savoir .bg img {
        height: 27px;
        margin-right: 10px; }
      .flexible .section_bon_a_savoir .bg .title {
        color: #ffffff;
        font-family: "Public Sans";
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 26px;
        text-align: left; }
      .flexible .section_bon_a_savoir .bg p {
        margin-bottom: 0; }
  .flexible .section_encart img {
    margin: auto; }

.back-btn-container {
  width: 100%; }
  .back-btn-container:after {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    background-color: #253365; }
  .back-btn-container a {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    padding-bottom: 30px; }
    .back-btn-container a:before {
      content: "";
      background-image: url("../img/icon/arr.svg");
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(-180deg);
      background-size: 20px;
      width: 24px;
      height: 18px;
      display: block;
      cursor: pointer;
      margin: 2px 10px 0 0;
      z-index: 1;
      float: left;
      transition: all ease 0.3s; }

/*
|
| Projects
|---------------
*/
#page-projects {
  background-color: #fff; }
  #page-projects .banner-intro a {
    text-decoration: underline; }
  #page-projects .block-mea article {
    background-color: #f5f5f5;
    height: 210px;
    padding: 40px; }
    @media (max-width: 991.98px) {
      #page-projects .block-mea article {
        height: initial;
        padding: 30px 20px; } }
    #page-projects .block-mea article .elem:after {
      content: "";
      width: 1px;
      background-color: #c0c0c0;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 130px; }
      @media (max-width: 991.98px) {
        #page-projects .block-mea article .elem:after {
          content: none; } }
    #page-projects .block-mea article .elem:last-child:after {
      content: none; }
    #page-projects .block-mea article .elem h2 {
      color: #253365;
      font-family: "Public Sans";
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left; }
      @media (max-width: 991.98px) {
        #page-projects .block-mea article .elem h2 {
          font-size: 20px; } }
    #page-projects .block-mea article .elem.elem-perf {
      text-align: right; }
      @media (max-width: 991.98px) {
        #page-projects .block-mea article .elem.elem-perf {
          text-align: initial; } }
  #page-projects .list-solutions .hide {
    animation: hide .5s ease 0s 1 normal forwards;
    transform-origin: center;
    display: none; }
  #page-projects .list-solutions .show {
    animation: show .5s ease 0s 1 normal forwards;
    transform-origin: center;
    display: block; }

@keyframes hide {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0; } }

@keyframes show {
  0% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0; }
  100% {
    transform: scale(1); } }
  #page-projects .list-solutions .why-title {
    cursor: pointer; }
    #page-projects .list-solutions .why-title span {
      width: calc(100% - 40px); }
    #page-projects .list-solutions .why-title:before {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 30px);
      background-color: #253365;
      position: absolute;
      bottom: -10px; }
    #page-projects .list-solutions .why-title:after {
      content: "";
      background-image: url("../img/icon/arr-blue.svg");
      height: 15px;
      width: 25px;
      display: block;
      position: absolute;
      top: 11px;
      right: 30px;
      transition: all ease 0.3s;
      transform: rotate(-180deg); }
    #page-projects .list-solutions .why-title.active:after {
      content: "";
      transition: all ease 0.3s;
      transform: rotate(0deg); }
  #page-projects .list-solutions .filters {
    margin: 20px 0;
    justify-content: center;
    text-align: center; }
    #page-projects .list-solutions .filters .filter {
      color: #989fb9;
      font-family: "Public Sans";
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      padding: 0 79px;
      transition: all ease 0.3s; }
      @media (max-width: 1399.98px) {
        #page-projects .list-solutions .filters .filter {
          padding: 0 52px; } }
      @media (max-width: 1199.98px) {
        #page-projects .list-solutions .filters .filter {
          padding: 0 27px; } }
      @media (max-width: 991.98px) {
        #page-projects .list-solutions .filters .filter {
          padding: 0;
          text-align: center; } }
      #page-projects .list-solutions .filters .filter.active, #page-projects .list-solutions .filters .filter:hover {
        transition: all ease 0.3s;
        color: #253365;
        opacity: 1; }
      #page-projects .list-solutions .filters .filter:after {
        content: "";
        width: 2px;
        opacity: 1;
        background-color: #253365;
        display: block;
        position: absolute;
        right: 0px;
        top: 14px;
        height: 25px; }
        @media (max-width: 991.98px) {
          #page-projects .list-solutions .filters .filter:after {
            content: none; } }
      #page-projects .list-solutions .filters .filter:first-child {
        text-align: left;
        padding: 0 62px 0 0; }
        @media (max-width: 1399.98px) {
          #page-projects .list-solutions .filters .filter:first-child {
            padding: 0 50px 0 0; } }
        @media (max-width: 991.98px) {
          #page-projects .list-solutions .filters .filter:first-child {
            padding: 0;
            text-align: center; } }
      #page-projects .list-solutions .filters .filter:last-child {
        text-align: right;
        padding: 0 0 0 62px; }
        @media (max-width: 1399.98px) {
          #page-projects .list-solutions .filters .filter:last-child {
            padding: 0 0 0 50px; } }
        @media (max-width: 991.98px) {
          #page-projects .list-solutions .filters .filter:last-child {
            padding: 0;
            text-align: center; } }
      #page-projects .list-solutions .filters .filter:last-child:after {
        content: none; }
    #page-projects .list-solutions .filters .filter:hover {
      cursor: pointer; }
  #page-projects .list-palmares h2 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left; }
  #page-projects .list-palmares .sstitle {
    text-align: left; }
    @media (max-width: 991.98px) {
      #page-projects .list-palmares .sstitle {
        text-align: center; } }
  #page-projects .list-palmares .perf {
    text-align: right; }
    @media (max-width: 991.98px) {
      #page-projects .list-palmares .perf {
        text-align: center; } }

.list-filter .link-card,
.block-solutions .link-card {
  left: 20px; }
  @media (max-width: 767.98px) {
    .list-filter .link-card,
    .block-solutions .link-card {
      left: 0px; } }

@media (max-width: 991.98px) {
  .list-filter .tabs,
  .block-solutions .tabs {
    text-align: left !important; } }

#page-news-archive .hide {
  animation: hide .5s ease 0s 1 normal forwards;
  transform-origin: center;
  display: none; }

#page-news-archive .show {
  animation: show .5s ease 0s 1 normal forwards;
  transform-origin: center;
  display: block; }

@keyframes hide {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0; } }

@keyframes show {
  0% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0; }
  100% {
    transform: scale(1); } }

#page-news-archive .link-card {
  left: 30px !important; }
  @media (max-width: 767.98px) {
    #page-news-archive .link-card {
      left: 0px !important; } }

#page-news-archive .firstcard .link-card {
  left: 0px !important; }

.filters {
  position: relative;
  width: 100%;
  z-index: 1; }
  .filters .filter-mobil {
    width: 100%; }
    @media (max-width: 991.98px) {
      .filters .filter-mobil {
        display: none; } }
  .filters a.selecteur {
    position: relative;
    text-align: left; }
    @media (max-width: 991.98px) {
      .filters a.selecteur {
        border: none;
        border-bottom: 1px solid #253365;
        padding: 10px 20px; } }
    .filters a.selecteur.active:after {
      content: "";
      transform: rotate(-180deg); }
    .filters a.selecteur:after {
      content: "";
      background-image: url("../img/icon/arr-blue.svg");
      background-size: contain;
      background-position: 0 0px;
      background-repeat: no-repeat;
      height: 15px;
      width: 20px;
      display: block;
      position: absolute;
      top: 17px;
      right: 20px;
      transition: all ease 0.3s;
      transform: rotate(0deg); }
  .filters a.all {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    padding: 0;
    border-bottom: 1px solid #253365; }
    @media (max-width: 991.98px) {
      .filters a.all {
        border: 1px solid #253365;
        border-top: none;
        padding: 10px 20px; } }
  @media (max-width: 991.98px) {
    .filters a {
      border-radius: 0;
      padding: 10px 20px;
      font-size: 16px;
      border: 1px solid #253365;
      border-top: none;
      margin: 0 !important; } }

.line-blue {
  position: relative; }
  .line-blue:after {
    content: "";
    width: calc(100% - 30px);
    height: 1px;
    display: block;
    position: absolute;
    background-color: #253365;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

/**
 * Overlay IE Browser
 */
.browser-ie {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .browser-ie {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .browser-ie img {
    margin-bottom: 50px; }
  .browser-ie .text-info {
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    color: #000000; }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home .banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 600px;
  margin-top: 89px;
  background-color: #000000;
  overflow: hidden; }
  #page-home .banner:before {
    content: "";
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  #page-home .banner .item-content {
    width: 100%;
    padding: 0px 0; }
    @media (max-width: 991.98px) {
      #page-home .banner .item-content {
        padding: 30px 0 0 0; } }
    @media (max-width: 767.98px) {
      #page-home .banner .item-content .container, #page-home .banner .item-content #header .header-container, #header #page-home .banner .item-content .header-container, #page-home .banner .item-content #mobile-menu .item-container, #mobile-menu #page-home .banner .item-content .item-container {
        text-align: center; } }
    #page-home .banner .item-content .container .surtitle, #page-home .banner .item-content #header .header-container .surtitle, #header #page-home .banner .item-content .header-container .surtitle, #page-home .banner .item-content #mobile-menu .item-container .surtitle, #mobile-menu #page-home .banner .item-content .item-container .surtitle {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      margin: 0px 0 10px 0;
      text-transform: uppercase; }
      @media (max-width: 991.98px) {
        #page-home .banner .item-content .container .surtitle, #page-home .banner .item-content #header .header-container .surtitle, #header #page-home .banner .item-content .header-container .surtitle, #page-home .banner .item-content #mobile-menu .item-container .surtitle, #mobile-menu #page-home .banner .item-content .item-container .surtitle {
          margin: 35px 0 10px 0; } }
      @media (max-width: 767.98px) {
        #page-home .banner .item-content .container .surtitle, #page-home .banner .item-content #header .header-container .surtitle, #header #page-home .banner .item-content .header-container .surtitle, #page-home .banner .item-content #mobile-menu .item-container .surtitle, #mobile-menu #page-home .banner .item-content .item-container .surtitle {
          text-align: center; } }
    #page-home .banner .item-content .container .big-title, #page-home .banner .item-content #header .header-container .big-title, #header #page-home .banner .item-content .header-container .big-title, #page-home .banner .item-content #mobile-menu .item-container .big-title, #mobile-menu #page-home .banner .item-content .item-container .big-title {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 48px;
      line-height: 50px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      text-align: left; }
      @media (max-width: 1199.98px) {
        #page-home .banner .item-content .container .big-title, #page-home .banner .item-content #header .header-container .big-title, #header #page-home .banner .item-content .header-container .big-title, #page-home .banner .item-content #mobile-menu .item-container .big-title, #mobile-menu #page-home .banner .item-content .item-container .big-title {
          font-size: 40px; } }
      @media (max-width: 991.98px) {
        #page-home .banner .item-content .container .big-title, #page-home .banner .item-content #header .header-container .big-title, #header #page-home .banner .item-content .header-container .big-title, #page-home .banner .item-content #mobile-menu .item-container .big-title, #mobile-menu #page-home .banner .item-content .item-container .big-title {
          font-size: 28px;
          line-height: 37px; } }
      @media (max-width: 767.98px) {
        #page-home .banner .item-content .container .big-title, #page-home .banner .item-content #header .header-container .big-title, #header #page-home .banner .item-content .header-container .big-title, #page-home .banner .item-content #mobile-menu .item-container .big-title, #mobile-menu #page-home .banner .item-content .item-container .big-title {
          font-size: 20px;
          line-height: 26px;
          text-align: center; } }
      #page-home .banner .item-content .container .big-title .item-line, #page-home .banner .item-content #header .header-container .big-title .item-line, #header #page-home .banner .item-content .header-container .big-title .item-line, #page-home .banner .item-content #mobile-menu .item-container .big-title .item-line, #mobile-menu #page-home .banner .item-content .item-container .big-title .item-line {
        text-align: left; }
        @media (max-width: 991.98px) {
          #page-home .banner .item-content .container .big-title .item-line, #page-home .banner .item-content #header .header-container .big-title .item-line, #header #page-home .banner .item-content .header-container .big-title .item-line, #page-home .banner .item-content #mobile-menu .item-container .big-title .item-line, #mobile-menu #page-home .banner .item-content .item-container .big-title .item-line {
            text-align: center; } }
    #page-home .banner .item-content .container .resume, #page-home .banner .item-content #header .header-container .resume, #header #page-home .banner .item-content .header-container .resume, #page-home .banner .item-content #mobile-menu .item-container .resume, #mobile-menu #page-home .banner .item-content .item-container .resume {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left; }
      @media (max-width: 767.98px) {
        #page-home .banner .item-content .container .resume, #page-home .banner .item-content #header .header-container .resume, #header #page-home .banner .item-content .header-container .resume, #page-home .banner .item-content #mobile-menu .item-container .resume, #mobile-menu #page-home .banner .item-content .item-container .resume {
          text-align: center; } }
    #page-home .banner .item-content .container .link a, #page-home .banner .item-content #header .header-container .link a, #header #page-home .banner .item-content .header-container .link a, #page-home .banner .item-content #mobile-menu .item-container .link a, #mobile-menu #page-home .banner .item-content .item-container .link a {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 21px;
      text-align: left;
      position: relative;
      transition: all ease 0.3s; }
      @media (max-width: 767.98px) {
        #page-home .banner .item-content .container .link a, #page-home .banner .item-content #header .header-container .link a, #header #page-home .banner .item-content .header-container .link a, #page-home .banner .item-content #mobile-menu .item-container .link a, #mobile-menu #page-home .banner .item-content .item-container .link a {
          margin-left: -50px; } }
      #page-home .banner .item-content .container .link a:after, #page-home .banner .item-content #header .header-container .link a:after, #header #page-home .banner .item-content .header-container .link a:after, #page-home .banner .item-content #mobile-menu .item-container .link a:after, #mobile-menu #page-home .banner .item-content .item-container .link a:after {
        content: "";
        background-image: url("../img/icon/arr-w.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        width: 21px;
        height: 13px;
        display: block;
        cursor: pointer;
        margin: 0px 0 0 10px;
        position: absolute;
        top: 5px;
        right: -40px;
        z-index: 1;
        transition: all ease 0.3s; }
      #page-home .banner .item-content .container .link a:before, #page-home .banner .item-content #header .header-container .link a:before, #header #page-home .banner .item-content .header-container .link a:before, #page-home .banner .item-content #mobile-menu .item-container .link a:before, #mobile-menu #page-home .banner .item-content .item-container .link a:before {
        content: "";
        height: 0;
        width: 0;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        right: -150px;
        left: 0;
        bottom: 0;
        float: right;
        transition: all ease 0.3s;
        margin: auto;
        opacity: 0; }
      #page-home .banner .item-content .container .link a:hover:after, #page-home .banner .item-content #header .header-container .link a:hover:after, #header #page-home .banner .item-content .header-container .link a:hover:after, #page-home .banner .item-content #mobile-menu .item-container .link a:hover:after, #mobile-menu #page-home .banner .item-content .item-container .link a:hover:after {
        content: "";
        right: -40px;
        transition: all ease 0.3s; }
      #page-home .banner .item-content .container .link a:hover:before, #page-home .banner .item-content #header .header-container .link a:hover:before, #header #page-home .banner .item-content .header-container .link a:hover:before, #page-home .banner .item-content #mobile-menu .item-container .link a:hover:before, #mobile-menu #page-home .banner .item-content .item-container .link a:hover:before {
        content: "";
        height: 40px;
        width: 40px;
        opacity: 1;
        transition: all ease 0.3s; }
    @media (max-width: 991.98px) {
      #page-home .banner .item-content .container .link, #page-home .banner .item-content #header .header-container .link, #header #page-home .banner .item-content .header-container .link, #page-home .banner .item-content #mobile-menu .item-container .link, #mobile-menu #page-home .banner .item-content .item-container .link {
        margin-bottom: 50px; } }
    #page-home .banner .item-content .link-zone {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0; }
      @media (max-width: 991.98px) {
        #page-home .banner .item-content .link-zone {
          position: initial; } }
      #page-home .banner .item-content .link-zone .link-banner .item {
        height: 200px;
        width: 100%;
        padding: 40px 80px 40px 30px;
        position: relative;
        transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1); }
        @media (max-width: 991.98px) {
          #page-home .banner .item-content .link-zone .link-banner .item {
            padding: 15px 94px 15px 30px;
            position: relative;
            height: 120px;
            display: flex;
            vertical-align: middle;
            align-items: center; } }
        #page-home .banner .item-content .link-zone .link-banner .item:after {
          content: "";
          background-image: url("../img/icon/arr-w.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
          border: 1px solid #fff;
          border-radius: 50%;
          width: 54px;
          height: 54px;
          display: block;
          cursor: pointer;
          margin: 0px;
          position: absolute;
          bottom: 15px;
          right: 20px;
          z-index: 1;
          transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1); }
          @media (max-width: 991.98px) {
            #page-home .banner .item-content .link-zone .link-banner .item:after {
              top: 33px;
              bottom: initial; } }
        #page-home .banner .item-content .link-zone .link-banner .item:nth-child(1) {
          background-color: #0587fc; }
        #page-home .banner .item-content .link-zone .link-banner .item:nth-child(2) {
          background-color: #105a9d; }
        #page-home .banner .item-content .link-zone .link-banner .item:nth-child(3) {
          background-color: #253365; }
        #page-home .banner .item-content .link-zone .link-banner .item .firstword {
          display: block; }
          @media (max-width: 991.98px) {
            #page-home .banner .item-content .link-zone .link-banner .item .firstword {
              display: inline-block; } }
        #page-home .banner .item-content .link-zone .link-banner .item.inactive {
          opacity: 0.7 !important; }
        #page-home .banner .item-content .link-zone .link-banner .item:hover:after {
          content: "";
          right: 15px;
          background-color: #fff;
          background-image: url("../img/icon/arr.svg");
          transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1); }
        #page-home .banner .item-content .link-zone .link-banner .item .title {
          color: #ffffff;
          font-family: "Public Sans";
          font-size: 24px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: normal;
          line-height: 28px;
          text-align: left; }
          @media (max-width: 1199.98px) {
            #page-home .banner .item-content .link-zone .link-banner .item .title {
              font-size: 20px; } }

#page-home .news .all-posts {
  color: #253365;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  float: right;
  padding-top: 20px;
  transition: all ease 0.3s; }
  @media (max-width: 991.98px) {
    #page-home .news .all-posts {
      padding-top: 10px; } }
  @media (max-width: 575.98px) {
    #page-home .news .all-posts {
      display: none; } }
  #page-home .news .all-posts:before {
    content: "";
    height: 0;
    width: 0;
    border: 1px solid #253365;
    border-radius: 50%;
    position: absolute;
    top: 17px;
    right: -170px;
    left: 0;
    bottom: 0;
    float: right;
    transition: all ease 0.3s;
    margin: auto;
    opacity: 0; }
  #page-home .news .all-posts:hover:before {
    content: "";
    height: 40px;
    width: 40px;
    opacity: 1;
    transition: all ease 0.3s; }
  #page-home .news .all-posts:after {
    content: "";
    background-image: url("../img/icon/arr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 24px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 2px 0px 0 14px;
    z-index: 1;
    float: right;
    transition: all ease 0.3s; }

#page-home .news .mob-actu {
  display: none; }
  @media (max-width: 575.98px) {
    #page-home .news .mob-actu {
      display: block; } }
  #page-home .news .mob-actu a {
    padding: 10px 20px;
    float: inherit;
    margin: 30px 0 0 0;
    border: 1px solid #253365;
    display: block;
    font-weight: 500; }

#page-home .news .line {
  line-height: 42px !important; }

#page-home .block-finances {
  min-height: 600px; }
  #page-home .block-finances .flex {
    display: flex;
    align-items: center;
    height: 600px;
    padding: 0 60px;
    z-index: 2; }
    @media (max-width: 991.98px) {
      #page-home .block-finances .flex {
        height: initial;
        padding: 0 15px; } }
    #page-home .block-finances .flex .link {
      position: relative;
      display: inline-block;
      transition: all ease 0.3s;
      font-weight: 600; }
      #page-home .block-finances .flex .link:before {
        content: "";
        height: 0;
        width: 0;
        border: 1px solid #253365;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        right: -119px;
        left: 0;
        bottom: 0;
        float: right;
        transition: all ease 0.3s;
        margin: auto;
        opacity: 0; }
      #page-home .block-finances .flex .link:hover:before {
        content: "";
        height: 40px;
        width: 40px;
        opacity: 1;
        transition: all ease 0.3s; }
      #page-home .block-finances .flex .link:after {
        content: "";
        background-image: url("../img/icon/arr.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        width: 24px;
        height: 18px;
        display: block;
        cursor: pointer;
        margin: 3px 0px 0 17px;
        z-index: 1;
        float: right;
        transition: all ease 0.3s; }
  #page-home .block-finances .intro-img {
    position: absolute;
    top: 90px;
    right: 0;
    z-index: 1; }
    @media (max-width: 991.98px) {
      #page-home .block-finances .intro-img {
        position: relative;
        top: initial;
        right: initial; } }
    #page-home .block-finances .intro-img .img {
      background-size: cover;
      background-position: center;
      height: 740px; }
      @media (max-width: 991.98px) {
        #page-home .block-finances .intro-img .img {
          height: 500px; } }
      @media (max-width: 767.98px) {
        #page-home .block-finances .intro-img .img {
          height: 340px; } }

#page-home .block-homelink {
  color: white; }
  #page-home .block-homelink .item {
    height: 260px;
    position: relative;
    overflow: hidden; }
    #page-home .block-homelink .item.block-esp:before {
      content: "";
      background-image: url(../img/global/nouveau.svg);
      height: 172px;
      width: 175px;
      display: block;
      top: -15px;
      right: -15px;
      position: absolute;
      opacity: 0;
      transition: all ease 0.3s; }
    #page-home .block-homelink .item.block-esp:hover:before {
      content: "";
      transition: all ease 0.3s;
      opacity: 1;
      top: 0px;
      right: 0px; }
    #page-home .block-homelink .item h3 {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 28px;
      text-align: left; }
    #page-home .block-homelink .item .desc {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 20px;
      text-align: left; }
    #page-home .block-homelink .item .title {
      width: 104px;
      height: 24px;
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      float: right;
      position: absolute;
      right: 34px;
      bottom: 35px;
      margin-right: 60px; }
      #page-home .block-homelink .item .title:after {
        content: "";
        background-image: url("../img/icon/arr-w.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        width: 54px;
        height: 54px;
        display: block;
        cursor: pointer;
        margin: -14px -63px 0 0;
        bottom: 15px;
        right: 20px;
        z-index: 1;
        float: right; }

/*
|--------------------
|      NEWS
|--------------------
*/
#page-news-archive .js-loaded-posts > .card-filter {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|  Single Actualité
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(153, 153, 153, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(20, 20, 20, 0.6);
  font-weight: 400; }

/*
|--------------------
|  Single product
|--------------------
*/
.single-projects {
  background-color: #fff;
  color: #253365;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left; }
  .single-projects h1 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left; }
  .single-projects h2 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 37px;
    text-align: left; }
  .single-projects h3 {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
    text-transform: uppercase; }
  .single-projects .card-image .img-project {
    width: 233px;
    height: 233px;
    background-size: cover;
    border-radius: 50%;
    margin: auto; }
  .single-projects .ddl {
    color: #253365;
    font-family: "Public Sans";
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    position: relative; }
    .single-projects .ddl:after {
      content: "";
      background-image: url("../img/icon/ddl.svg");
      background-repeat: no-repeat;
      height: 18px;
      width: 18px;
      display: block;
      cursor: pointer;
      transition: all ease 0.3s;
      position: absolute;
      right: -24px;
      top: 3px; }

.block-info {
  position: relative; }
  @media (max-width: 991.98px) {
    .block-info {
      height: initial;
      margin: 0;
      padding-top: 30px; } }
  .block-info:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #253365;
    position: absolute;
    top: 0; }
  @media (max-width: 991.98px) {
    .block-info:first-child:after {
      content: initial; } }
  .block-info .info {
    display: block;
    padding-top: 24px;
    padding-bottom: 10px; }
    @media (max-width: 991.98px) {
      .block-info .info {
        height: initial;
        padding: 0 0px 15px 0px; } }
    .block-info .info span {
      display: initial;
      font-weight: bold; }

.card-projects .custom-card {
  height: 400px;
  border: 1px solid;
  padding: 15px 20px;
  position: relative; }
  @media (max-width: 1399.98px) {
    .card-projects .custom-card {
      height: 450px; } }
  @media (max-width: 1199.98px) {
    .card-projects .custom-card {
      height: 400px; } }
  @media (max-width: 991.98px) {
    .card-projects .custom-card {
      height: initial; } }
  .card-projects .custom-card .card-img-container {
    width: 110px;
    height: 110px; }
    .card-projects .custom-card .card-img-container .card-img {
      width: 110px;
      height: 110px;
      border-radius: 200px; }
  .card-projects .custom-card .card-content .cms {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    text-align: left; }
  .card-projects .custom-card .card-content .rate {
    width: 120px;
    float: left; }
  .card-projects .custom-card .card-content .perf {
    color: #253365;
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 17px;
    text-align: left;
    float: left; }
  .card-projects .custom-card:before {
    content: "";
    opacity: 0;
    border: 1px solid #253365;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
    position: absolute;
    transition: all ease 0.4s; }
  .card-projects .custom-card:hover:before {
    content: "";
    opacity: 1; }
  .card-projects .custom-card.custom-card-prod-struc .card-img-container {
    float: left;
    margin-right: 30px; }
  .card-projects .custom-card.custom-card-prod-struc .card-title-container {
    height: 110px; }
    .card-projects .custom-card.custom-card-prod-struc .card-title-container h2 {
      color: #253365;
      font-family: "Public Sans";
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left;
      padding: 0; }

#tabs .tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: right; }
  @media (max-width: 991.98px) {
    #tabs .tabs {
      text-align: center; } }
  #tabs .tabs li {
    display: inline-block;
    cursor: pointer;
    padding: 0 15px;
    color: #253365;
    font-weight: bold;
    text-transform: uppercase;
    position: relative; }
    #tabs .tabs li:before {
      content: "";
      height: 23px;
      width: 1px;
      display: inline-block;
      left: 0px;
      position: absolute;
      background-color: #253365; }
    #tabs .tabs li:first-child:before {
      content: none; }
    #tabs .tabs li.current span {
      opacity: 1; }
    #tabs .tabs li span {
      opacity: 0.4; }

#tabs .tab-content {
  display: none; }

#tabs .tab-content.current {
  display: inherit; }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-contact .section-map #map {
    position: relative;
    width: 100%;
    height: 50vh;
    background-color: #000000; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  background-color: #253365; }
  #page-404:before {
    content: "";
    background-image: url("../img/global/bg-404-l.svg");
    background-size: cover;
    width: 350px;
    height: 362px;
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 991.98px) {
      #page-404:before {
        width: 290px;
        height: 302px; } }
  #page-404:after {
    content: "";
    background-image: url("../img/global/bg-404-r.svg");
    background-size: cover;
    width: 350px;
    height: 362px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0; }
    @media (max-width: 991.98px) {
      #page-404:after {
        width: 290px;
        height: 302px; } }
  #page-404 .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: #FFFFFF;
    text-align: center; }
    #page-404 .section-content h1 {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 50px;
      font-weight: 100;
      font-style: normal;
      letter-spacing: normal;
      line-height: 34px;
      text-align: center; }
    #page-404 .section-content .txt {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 22px;
      text-align: center; }
    #page-404 .section-content .btn_arrow {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      position: relative;
      display: inline-block;
      margin-left: -20px; }
      #page-404 .section-content .btn_arrow:after {
        content: "";
        background-image: url("../img/icon/arr-w.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        width: 21px;
        height: 13px;
        display: block;
        cursor: pointer;
        margin: 0;
        position: absolute;
        left: initial;
        right: -30px;
        top: 6px;
        transition: all ease 0.3s; }
      #page-404 .section-content .btn_arrow:hover:after {
        transition: all ease 0.3s;
        right: -40px; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  -webkit-appearance: none;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

select {
  background-image: url("../img/icon/bottom.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap .gform_fields input, .gravity-form-bootstrap .gform_fields textarea {
  width: 100%;
  background-color: transparent; }

/*
|
| Materialize form 
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px;
    font-weight: 400;
    font-size: 18px; }
  .materialize-form .materialize-form-group input {
    margin-bottom: 35px;
    padding: 7px 0;
    border: 0;
    border-bottom: 2px solid #f5f5f5; }
  .materialize-form .materialize-form-group.gfield_error label {
    top: -20px; }

/*
|
| Contact form 
|---------------
*/
.contact-form label {
  color: #141414; }

.contact-form textarea {
  border: 2px #f5f5f5 solid;
  height: 200px;
  padding: 15px;
  color: #000000;
  font-size: 18px;
  border-radius: 2px; }
  .contact-form textarea::placeholder {
    color: #000000; }

.contact-form .gform_body {
  margin-bottom: 20px; }

.contact-form .gform_footer {
  text-align: right; }

.contact-form input[type="submit"] {
  position: relative;
  background-color: transparent;
  color: #424242;
  padding: 15px 30px;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  cursor: pointer;
  border: 2px solid #f5f5f5;
  border-radius: 2px; }
  .contact-form input[type="submit"]:hover {
    color: #FFFFFF;
    background: #f5f5f5; }

.contact-form .gform_confirmation_message {
  color: #141414; }

.contact-form .validation_message {
  margin-top: 5px;
  color: #BB0B0B;
  font-size: 14px; }

.validation_error {
  margin-bottom: 30px;
  color: #BB0B0B !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #141414;
    text-align: center; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #141414;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }
