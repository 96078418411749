/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  background-color: #253365;

  &:before {
    content: "";
    background-image: url('../img/global/bg-404-l.svg');
    background-size: cover;
    width: 350px;
    height: 362px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include media-breakpoint-down(md) {
      width: 290px;
      height: 302px;
    }
  }

  &:after {
    content: "";
    background-image: url('../img/global/bg-404-r.svg');
    background-size: cover;
    width: 350px;
    height: 362px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    @include media-breakpoint-down(md) {
      width: 290px;
      height: 302px;
    }
  }

  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: $white;
    text-align: center;

    h1 {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 50px;
      font-weight: 100;
      font-style: normal;
      letter-spacing: normal;
      line-height: 34px;
      text-align: center;
    }

    .txt {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 22px;
      text-align: center;
    }

    .btn_arrow {
      color: #ffffff;
      font-family: "Public Sans";
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: normal;
      line-height: 24px;
      text-align: left;
      position: relative;
      display: inline-block;
      margin-left: -20px;

      &:after {
        content: "";
        background-image: url('../img/icon/arr-w.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        width: 21px;
        height: 13px;
        display: block;
        cursor: pointer;
        margin: 0;
        position: absolute;
        left: initial;
        right: -30px;
        top: 6px;
        transition: all ease 0.3s;
      }

      &:hover {
        &:after {
          transition: all ease 0.3s;
          right: -40px;
        }
      }
    }
  }
}
